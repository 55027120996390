import React from "react";
import Johnny from "../coldtake-pictures/johnnyutah.jpeg";
import cinderella from "../../src/pictures/lucky-you/cinderella-burn.jpeg";
import vader from "../../src/pictures/sequel-wins/vader.jpeg";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import BoozeCruize1 from "../grabbag-pictures/booze-cruize-1.jpeg";
import BoozeCruize2 from "../grabbag-pictures/booze-cruize-2.jpeg";
import BoozeCruize4 from "../grabbag-pictures/booze-cruize-4.jpeg";
import BoozeCruize3 from "../grabbag-pictures/booze-cruize-3.jpeg";
import BoozeCruize5 from "../grabbag-pictures/booze-cruize-5.jpeg";
import BoozeCruize6 from "../grabbag-pictures/booze-cruize-6.jpeg";
import BoozeCruize23 from "../grabbag-pictures/booze-cruize-23.jpeg";
import NotificationBell from "../NotificationBell";

function BoozeCruize() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingGrabbag">
          <span className="underline">Grab Bag</span>
        </Link>{" "}
        &gt; Ride the Booze Cruize
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Ride the Booze Cruize</p>
          </div>
          <div className="published">PUBLISHED Aug 23, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/Ashton">
                <span className="blue" id="author-name">
                  Ashton
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Ride the Booze Cruize"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={BoozeCruize1}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Tom says, "It's not to OK to 𝙞𝙢𝙗𝙞𝙗𝙚 and 𝙙𝙧𝙞𝙗𝙚."
              </p>
            </div>
            <p>
              Tom Cruise is the most well known movie star today. That’s not a
              surprise, considering the incredible list of blockbuster hits he’s
              been pumping out non-stop since 1983.
            </p>
            <p>
              While maybe most known as Maverick, Jack Reacher, or Ethan Hunt;
              Cruise doesn’t miss with his one-off roles either. Love him or
              hate him, if you throw on a flick with Tom Cruise’s face on the
              cover-- it’s going to be entertaining. One of the few actors who
              can tout off a title list where none of them really make you go,
              “Why would he do that movie?”.
            </p>
            <p>
              So let’s sit back, relax, crack or mix-up some recommended cold
              ones and watch Tom sprint from scene to scene on a curated movie
              marathon:
              <span className="green"> Welcome to the Booze Cruize</span> .
            </p>
            <h2>Cruize #1: Drive the Action</h2>
            <div className="image-and-caption">
              <img
                src={BoozeCruize2}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption"></p>
            </div>
            <p>
              <h3>Top Gun (1986) - Days of Thunder (1990) - Oblivion (2013)</h3>
              <p>
                <strong>Bonus:</strong> <i>American Made</i> (2017)
              </p>
            </p>
            <p>
              Ride along on this fast paced tour as Tom earns his heavy
              equipment operator's license while cashing bad checks, making 200
              mph left-hand turns, and flying futuristic search and destroy
              drones that still need human pilots.
            </p>
            <p>
              Bonus: If you're still feeling the high flying action, check out
              Tom wheel and deal throughout the America's during the late 1970's
              and early 80's.
            </p>

            <h2>Cruize #2: Campaign Tour</h2>
            <div className="image-and-caption">
              <img
                src={BoozeCruize3}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption"></p>
            </div>
            <p>
              <h3>
                Born on the Fourth of July (1988) - A Few Good Men (1992) -
                Lions for Lambs (2007)
              </h3>
            </p>
            <p>
              Travel across state lines and explore some of Cruise’s more
              dramatic roles for the good of his country. A staunch conservative
              willing to die hard for the red, white, & blue, this soldier soon
              leaves the combat-zone for the courtroom and winds up achieving
              higher political ambitions than he had ever dreamed.
            </p>
            <h2>Cruize #3: Young Professional's Commute</h2>
            <div className="image-and-caption">
              <img
                src={BoozeCruize4}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption"></p>
            </div>
            <p>
              <h3>Losin’ It (1982) - Cocktail (1988) - The Firm (1993)</h3>
              <p>
                <strong>Bonus:</strong> <i>Knight & Day</i> (2010)
              </p>
            </p>

            <p>
              An innocent highschool virgin heads to Tijuana in search of some
              action. What he finds is an addition to booze, girls, and
              showing-off slick bartending moves. After years of late night
              partying, this yuppie ne’er do well finally gets his shit
              together, goes to college, passes the bar, and is hired by a
              prestigious law firm. While working on the biggest case of his
              young career, he finds a drug new addition: adrenaline.
            </p>
            <p>
              Bonus: Time to take the edge off, kick back and relax with this
              Action Rom-Com.
            </p>
            <h2>Cruize #4: Hot Heads & Cold Bodies</h2>
            <div className="image-and-caption">
              <img
                src={BoozeCruize5}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption"></p>
            </div>

            <p>
              <h3>
                The Color of Money (1986) - Collateral (2004) - Jack Reacher
                (2012)
              </h3>
              <p>
                <strong>Bonus:</strong> <i>Jack Reacher: Never Back Down</i>{" "}
                (2016)
              </p>
            </p>
            <p>
              Burned as a young hustler, Cruise decides he is never going to
              lose a girl to Paul Newman again and vows to become the best
              killer he can be; with no remorse or emotion getting in the way.
              Eventually becoming the cool, calm,collected retired Army MP
              turned assassin for justice. Immediately roll into JR2 if you
              didn't get enough of TC's pistol accuracy and awareness of who is
              on the other end of a phone before answering.
            </p>
            <p>Bonus: The hits just keep on comin'.</p>
            <h2>Cruize #5: Road to Fame and Fortune</h2>
            <div className="image-and-caption">
              <img
                src={BoozeCruize6}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption"> </p>
            </div>
            <p>
              <h3>
                All the Right Moves (1983) - Jerry Maguire (1996) - Rock of Ages
                (2012)
              </h3>
              <p>
                <strong>Bonus:</strong> <i>Tropic Thunder</i> (2008)
              </p>
            </p>
            <p>
              Take a trip from humble small town beginnings to big city
              celebrity status. Young Curise is stuck with a hopeless future at
              the steel mill but determined to get out the only way he knows
              how: by crying and playing football. When a likely injury during
              college sidelines him as a player, the former athlete becomes a
              solo sports agent. After catering to the social elite for years,
              he is finally recognized for his vocal talent and acheives Rock ‘n
              Roll super-stardom.
            </p>
            <p>
              Bonus: Retirement from music has brought back the love of
              management, although not a starring role, this scene stealing
              gross-man is worth a watch.
            </p>
            <div className="image-and-caption">
              <img
                src={BoozeCruize23}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"Shue look beautiful today." </p>
            </div>
            <p>
              Of course, if you’re feeling a lack of double crossing, face
              swapping, undercover former operative suspending from wires or
              cables or ropes, quick cool one-liners, car chases, twists, turns,
              explosions and heists in your life… you could always go with
              <Link to="/fakemovies">
                {" "}
                <span className="green">
                  {" "}
                  <u>𝘼𝙪𝙨𝙩𝙞𝙣𝙥𝙪𝙨𝙨𝙮</u>
                </span>{" "}
              </Link>
              .
            </p>
            <p>
              Then again you’d get more of that with the Mission’s Impossible
              series.
            </p>
          </div>{" "}
          {/* <Link to=""> */}
          <div className="readMoreContainer">
            <p className="readMoreHeader">Read next:</p>
            <p className="readMoreContent">TBD</p>
            <p className="underscore"></p>
          </div>
          {/* </Link> */}
          <ShareSquare
            description={"Check out this article from FilmSlobs! Hack is Wack"}
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/raresequelwins">
            <div className="image-and-caption">
              <img
                src={vader}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Rare Sequel Wins</p>
            </div>
          </Link>
          <Link to="/bestzingers">
            <div className="image-and-caption">
              <img
                src={cinderella}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">
                Best Zingers from Minor Characters
              </p>
            </div>
          </Link>
          <Link to="/johnny">
            <div className="image-and-caption">
              <img
                src={Johnny}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Johnny Utah is a Piece of Shit</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BoozeCruize;
