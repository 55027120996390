import React from "react";
import GambolMain from "../topfive-pictures/gambol-main.jpeg";
import Magnum from "../topfive-pictures/magnum.jpeg";
import Sweat1 from "../topfive-pictures/cold-sweats-4.jpg";
import Cobra from "../topfive-pictures/cobra.jpeg";
import Silent from "../topfive-pictures/silent.jpeg";
import Commando from "../topfive-pictures/commando.jpeg";
import Predator from "../topfive-pictures/predator.jpg";
import Metro from "../topfive-pictures/metro.jpeg";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import NotificationBell from "../NotificationBell";
import Utah from "../../src/coldtake-pictures/johnnyutah.jpeg";
import Boozecruize from "../../src/grabbag-pictures/BC_TN2.jpg";
import { Link } from "react-router-dom";

function BiggestGun() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingtopFive">
          <span className="underline">Top 5 Lists</span>
        </Link>{" "}
        &gt; Biggest Guns on Movie Posters
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Biggest Guns on Movie Posters</p>
          </div>
          <div className="published">PUBLISHED Jul 10, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/dean">
                <span className="blue" id="author-name">
                  Dean
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Top 5 Biggest Guns on Movie Posters"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <div className="article-image-vertical-div">
                <img
                  src={Metro}
                  className="article-image-vertical"
                  alt={"Player Avatar"}
                />
              </div>
              <p id="image-caption">Prepare to be blown away</p>
            </div>
            <p>
              We love a huge blockbuster with an even bigger gun on the poster
              (henceforth known as a <span className="green">Glockbuster</span>
              ). When a gun gets top billing, it's basically a rubber stamped
              'fuck yeah'. Whether the movie is genuinely good, or
              <em>so-bad-it's-good</em>, you know you're in for a treat.
            </p>
            <p>
              Before we dive in, let's be clear: we don't condone real-life
              violence in any way ... but movie violence?{" "}
              <strong>BLAST AWAY</strong>.
            </p>
            <div className="image-and-caption">
              <img
                src={Predator}
                className="article-image"
                alt={"Player Avatar"}
              />

              <p id="image-caption">Fuck pencils, let's push some lead</p>
            </div>

            <h2>5.) Magnum Force</h2>
            <div className="image-and-caption">
              <div className="article-image-vertical-div">
                <img
                  src={Magnum}
                  className="article-image-vertical"
                  alt={"Player Avatar"}
                />
              </div>
              <p id="image-caption">
                He'll need a monster condom for that magnum dong
              </p>
            </div>
            <p>
              The .44 Magnum. This is a gun that'll stop a car at 100 yards, put
              a hole right through the engine block. The kind of gun they use in
              Africa for killing elephants. If only Clint had a beautiful
              handmade holster made in Mexico. Perhaps he can borrow one from
              Travis Bickle.
            </p>

            <h2>4.) Cold Sweat</h2>

            <div className="image-and-caption">
              <img
                src={Sweat1}
                className="article-image"
                alt={"Player Avatar"}
              />

              <p id="image-caption">
                The gun broke out in a Cold Sweat when Bronson squeezed it
              </p>
            </div>
            <p>
              Bronson clutching a gun is 96.4% of his movie posters, but Cold
              Sweat stands out due to the size and variety of weapons. In one
              version he's holding some kind of pistol/semi-automatic rifle like
              Oliver Reed in <em>Sitting Target</em>, and in the other he's
              grasping what looks like two full-length water pipes he pinched
              off a cruise ship.
            </p>

            <h2>3.) Cobra</h2>
            <div className="image-and-caption">
              <div className="article-image-vertical-div">
                <img
                  src={Cobra}
                  className="article-image-vertical"
                  alt={"Player Avatar"}
                />
              </div>
              <p id="image-caption">
                Let's hope Cobra's trigger finger isn't Viperactive
              </p>
              <p>
                We don't know what on Earth this gun is, but it looks badass --
                some magnificent blend between the laser gun from Moonraker and
                the D5K Deutsche from GoldenEye. The only thing we know for sure
                is it's a two-handed gun that Sly will definitely shoot with one
                hand.
              </p>
              <p>
                It also looks deafeningly loud. Perhaps he should have chosen a
                gun with a ...
              </p>
            </div>
            <h2>2.) Silent Trigger</h2>
            <div className="image-and-caption">
              <div className="article-image-vertical-div">
                <img
                  src={Silent}
                  className="article-image-vertical"
                  alt={"Player Avatar"}
                />
              </div>
              <p id="image-caption">Silent AND deadly ... but not a stinker</p>
              <p>
                We're not even on Earth anymore with this one. Not to be outdone
                by Rocky again, Ivan Drago wields this monstrosity that looks
                like it could blast a hole in the moon. It makes the Death Star
                Superlaser look like a BB gun. We're not sure what kind of alien
                invasion Dolph is planning, but if it involves this gun, the
                entire galaxy is in trouble.
              </p>
            </div>
            <h2>1.) Commando</h2>
            <div className="image-and-caption">
              <div className="article-image-vertical-div">
                <img
                  src={Commando}
                  className="article-image-vertical"
                  alt={"Player Avatar"}
                />
              </div>
              <p id="image-caption">You won't be back</p>
              <p>
                I mean, for Christ's sake. If you're a bad guy staring down
                these four barrels, just say
                <em> hasta la vista, baby</em>. Did he tear that missle launcher
                off an assault <em>choppa? </em> We wouldn't{" "}
                <em>stick around</em> to find out.
              </p>
              <p>
                Remember, always practice safe movie-watching, and never try to
                recreate anything you see onscreen... unless you've got a gun
                with its own gravitational pull.
              </p>
            </div>
          </div>{" "}
          <Link to="/raresequelwins">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Rare Sequel Wins</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Top 5 Biggest Guns on Movie Posters"
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/johnny">
            <div className="image-and-caption">
              <img
                src={Utah}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Johnny Utah is a piece of shit</p>
            </div>
          </Link>
          <Link to="/boozecruize">
            <div className="image-and-caption">
              <img
                src={Boozecruize}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Ride the booze cruize</p>
            </div>
          </Link>
          <Link to="/mostdisappointing">
            <div className="image-and-caption">
              <img
                src={GambolMain}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Most disappointing badasses</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BiggestGun;
