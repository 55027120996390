import React from "react";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import iceman from "../../src/pictures/lucky-you/iceman.jpeg";

function BestTaglines() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingtopFive">
          <span className="underline">Top 5 Lists</span>
        </Link>{" "}
        &gt; Best Taglines
      </div>
      <div className="articleContentContainer">
        {" "}
        <div class="article-container">
          <div class="article-container-header">
            <p>Best Taglines</p>
          </div>
          <div className="published">PUBLISHED Oct 24, 2022</div>
          <div className="authorName">
            BY{" "}
            <span className="blue" id="author-name">
              Dean
            </span>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Top 5 Taglines."
              }
            />
          </div>
          <div class="article-container-body">
            <p>Here is the description paragraph. </p>
            <h2>5.) Some Movie</h2>
            <p>
              Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo consequat.
            </p>
            <div className="image-and-caption">
              <img
                src={iceman}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <h2>4.) Some Movie</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod temp incididunt ut labore et dolore magna aliqua. Ut enim
              ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p>
              Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodoat.
            </p>

            <div className="image-and-caption">
              <img
                src={iceman}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <p>
              “Why don’t we do the coat check? I’ve never done that before, and
              tonight seems as good a night as any, what with all the heavy
              clothing.”
            </p>
            <h2>3.) Some Movie</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p>
              Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo consequat.
            </p>

            <div className="image-and-caption">
              <img
                src={iceman}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <p>
              “Why don’t we do the coat check? I’ve never done that before, and
              tonight seems as good a night as any, what with all the heavy
              clothing.”
            </p>
            <h2>2.) Cobra</h2>
            <p>
              A little background for those who haven't seen it: Eric Bana plays
              a gambler trying to win the World Series of Poker.
            </p>
            <p>
              During his journey to the final table he decimates hordes of
              nameless, faceless gamblers -- throwaway characters who lose with
              a groan and grimace. None of them dreamed of shit-talking a main
              character ... until Michael Shannon showed up.
            </p>
            <div className="image-and-caption">
              <img
                src={iceman}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                This is from <i>The Iceman</i>, but dude is a stone-cold killer
                in <i>Lucky You</i>, too.
              </p>
            </div>

            <p>
              The two men find themselves in a heads up, all-in situation. Bana
              wins the pot and gloats: "Sorry I won’t be seeing you at the big
              dance". BUT! It's not over. There was a misdeal and a new river
              card comes out. The pot goes to Shannon.
            </p>

            <p>
              Instead of fixing Bana with a shit-eating grin and getting
              silently congratulated by extras, Shannon STANDS UP and says{" "}
              <b>
                "Guess you won’t be needing to press your dress quite yet,
                Cinderella."
              </b>
            </p>
            <div className="image-and-caption">
              <img
                src={iceman}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Behold my works, ye mighty, and despair.</p>
            </div>
            <p>
              He zinged Bana so fucking hard that they don't even show his face
              - the scene just cuts. It stings twice as much knowing the zinger
              was fueled by his very own{" "}
              <strong>'won't see you at the dance'</strong> comment. Bana was
              disemboweled by his own sword.
            </p>
            <p>
              <strong>Writers Note:</strong> We'd be remiss if we didn't mention
              that Bana had a slick retort ... much later in the movie. He finds
              a way back into the tournament and sits at Shannon's table.
              Shannon feigns surprise and arrogantly remarks "Look who's here!",
              to which Bana replies "I managed to get my dress pressed after
              all".
            </p>
            <p>
              {" "}
              That was a good line, but let’s be real - he had a lot of time to
              think it over. You know he went home and kicked his coffee table
              and punched the wall, stewing in rage. It haunted him for days.
            </p>
            <p>
              After his trash can overflowed with mountains of crumpled paper,
              he finally drafted the proper comeback and ran straight to the
              casino. There were plenty of poker tables he could've sat at, but
              he chose Shannon's - all so he could deliver his comeback.
            </p>
            <div className="image-and-caption">
              <img
                src={iceman}
                className="article-image"
                alt={"Player Avatar"}
                style={{ height: "330px" }}
              />
              <p id="image-caption"></p>
            </div>

            <h2>1.) The Last American Virgin</h2>
            <p>
              Cyrus the Virus is a criminal whose propensity for violence is
              exceeded only by his intellect. While incarcerated he earned two
              degrees (including his Juris Doctorate) and masterminded The
              Jailbird hijacking.
            </p>
            <p>
              This is a man who does not lose verbal exchanges. Be it the US
              Marshalls, the DEA, or even Danny Trejo (speaking Spanish, no
              less), Cyrus is never at a loss for words.
            </p>
            <div className="image-and-caption">
              <img
                src={iceman}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Look at this silver-tongued bastard.</p>
            </div>
            <p>
              So when Guard Falzon walks past his cell, he decides to have a bit
              of fun at this glorified rent-a-cop's expense. There's no way this
              key-jangling dolt is capable of witty repartee, right?
            </p>
            <div className="image-and-caption">
              <img
                src={iceman}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">So you've chosen death.</p>
            </div>
            <p>
              Cyrus says “Stewardess. Stewardess. What’s the in-flight movie
              today?”, and Guard Falzon summarily executes him.{" "}
              <strong>
                "I think you'll like it, Cyrus. It's called{" "}
                <em>'I'll Never Make Love to a Woman on the Beach Again'</em>,
                and it's preceded by the award-winning short,{" "}
                <em>'No More Steak for Me, Ever'</em>
              </strong>
              .
            </p>

            <div className="image-and-caption">
              <img
                src={iceman}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <p>
              The once glib orator is reduced to muttering a vulgar comment
              under his breath while Falzon struts away laughing ("you're a
              funny fucker, aren't you"). Without a doubt, Cyrus got crushed
              harder here than in the junkyard scene at the end.
            </p>
          </div>
          {/* <Link to="/bestzingers"> */}
          <div className="readMoreContainer">
            <p className="readMoreHeader">Read next:</p>
            <p className="readMoreContent">Top 5 Movie Taglines</p>
            <p className="underscore"></p>
          </div>
          {/* </Link> */}
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Top 5 Movie Taglines"
            }
          />
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>

          <div className="image-and-caption">
            <img src={iceman} className="article-image" alt={"Player Avatar"} />
            <p id="sidebar-image-caption">Here's an image caption</p>
          </div>
          <div className="image-and-caption">
            <img src={iceman} className="article-image" alt={"Player Avatar"} />
            <p id="sidebar-image-caption">Here's an image caption</p>
          </div>
          <div className="image-and-caption">
            <img src={iceman} className="article-image" alt={"Player Avatar"} />
            <p id="sidebar-image-caption">Here's an image caption</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BestTaglines;
