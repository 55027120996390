import React from "react";
import { Link } from "react-router-dom"; // ✅ Import Link from React Router
import Gadbrooks from "../src/pictures/author-pics/DeanGadbrooks.jpg";
import AshtonPic from "../src/pictures/author-pics/AshtonMarvin_2.png";
import SpencePic from "../src/pictures/author-pics/spenceDiamond.jpg";
import ScotlandPic from "../src/pictures/author-pics/Scotland-McBagpipe-1.png";

const authors = [
  {
    id: 1,
    name: "Dean Gadbrooks",
    title: "Writer/Standup",
    bio: "Dean is a writer and standup with a movie obsession. ",
    image: Gadbrooks,
    link: "./dean",
  },
  {
    id: 2,
    name: "Ashton Marvin",
    title: "Writer/Editor",
    bio: "Ashton lives for the moment a movie drops its own name on-screen.",
    image: AshtonPic,
    link: "./ashton",
  },
  {
    id: 3,
    name: "Spence Diamond",
    title: "Writer/Slob",
    bio: "Spence specializes in film critiques and deep dives into cinematic history.",
    image: SpencePic,
    link: "./spence",
  },
  {
    id: 4,
    name: "Scotland McBagpipe",
    title: "Writer",
    bio: "Scotland is a top lad that's bold and smooth - just like a fine whisky.",
    image: ScotlandPic,
    link: "./scotland",
  },
];

const Team = () => {
  return (
    <div className="team-container">
      <h2 className="meetTeam">
        Meet Our <span class="green">Team</span>
      </h2>

      <p className="aboutTeam">
        We're basically old school video store clerks - just a{" "}
        <span className="green">ragtag group of movie misfits</span> with strong
        opinions.
      </p>
      <br></br>
      <p className="aboutTeam">
        Snobs have called us philistines with puerile reviews, but we don't even
        know what 'philistine' or 'puerile' mean, so suck on THAT, nerds!
      </p>

      <div className="team-grid">
        {authors.map((author) => (
          <div key={author.id} className="team-card">
            <img
              src={author.image}
              alt={author.name}
              className="author-image"
            />
            <h3 className="authorTitle">{author.name}</h3>
            <p style={{ fontWeight: "bold", marginBottom: "1em" }}>
              {author.title}
            </p>
            <p className="bio">{author.bio}</p>

            <Link to={author.link} className="authorButton">
              Read More
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
