import React from "react";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import Rey from "../coldtake-pictures/Rey.webp";
import Foxx from "../coldtake-pictures/Foxx.jpg";
import Aldo from "../coldtake-pictures/aldo.webp";
import Aldo2 from "../coldtake-pictures/aldo-2.png";
import Pacino from "../coldtake-pictures/pacino.png";
import Pacino2 from "../coldtake-pictures/pacino.jpeg";
import Participant from "../coldtake-pictures/trophy.jpg";
import Collateral from "../coldtake-pictures/collateral.png";
import Foxx2 from "../coldtake-pictures/foxx-2.jpg";
import Kylo from "../coldtake-pictures/kylo.png";
import Binks from "../coldtake-pictures/binks.png";
import Landa from "../coldtake-pictures/landa.webp";
import PacinoGun from "../coldtake-pictures/pacinogun.jpeg";
import LawAbiding from "../coldtake-pictures/law-abiding.jpeg";
import Zingers from "../pictures/topfive/zingers.jpeg";
import koogler from "../topfive-pictures/koogler.jpeg";
import TooFar2Pee from "../topfive-pictures/2far2P.png";
import NotificationBell from "../NotificationBell.js";

function UndeservingWinners() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingColdTakes">
          <span className="underline">Cold Takes</span>
        </Link>{" "}
        &gt; Undeserving Winners
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Undeserving Winners</p>
          </div>
          <div className="published">PUBLISHED Sep 1, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/dean">
                <span className="blue" id="author-name">
                  Dean
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Undeserving Winners"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={Participant}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Great job! You sure were there</p>
            </div>
            <p>
              <strong>*Warning: article contains spoilers.*</strong>
            </p>
            <p>
              Movie audiences are told to cheer for the 'good guy' no matter
              what.
            </p>
            <p>
              <span className="green">NOT UP IN HERE</span>. At FilmSlobs we're
              all about backing the <span className="green">brainiacs</span> and
              <span className="green"> badasses</span> regardless of their moral
              compass. In real life, ethics obviously matter, but in Movieland?
              Give the villain a round of applause if they outsmart everyone!
            </p>
            <p>
              In this article, we expose 5 inept 'heroes' who clearly didn't
              have what it takes to beat their foes. We're measuring this
              strictly on the characters{" "}
              <span className="green">skill level</span>.
            </p>

            <p> </p>

            <p></p>

            <h2>5.) Max - Collateral</h2>
            <div className="image-and-caption">
              <img
                src={Collateral}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Sorry, no change</p>
            </div>
            <p>
              Tom Cruise's 'Vincent' is the embodiment of a captivating and
              enigmatic hitman. As a former{" "}
              <span className="green">spec-ops assassin</span>, he moves with
              the precision of a panther; every step{" "}
              <span className="green">calculated</span> and{" "}
              <span className="green">lethal</span>. His eyes convey a cold
              determination as he methodically eliminates his targets, and he
              does it all in an impeccably tailored suit that exudes
              professionalism and sophistication.
            </p>
            <p>
              Jamie Foxx's Max(x), on the other hand, is a{" "}
              <span className="green">cab driver</span>. That's it.
            </p>
            <div className="image-and-caption">
              <img src={Foxx} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption">
                "Jokes on you! One day I'm gonna be a LIMO driver!"
              </p>
            </div>
            <p>
              He's not a soldier, police officer, or fighter who's driving a cab
              as a side hustle. This is a man with exactly{" "}
              <span className="green">ZERO skills</span> and training in weapons
              or hand-to-hand combat. He doesn't even show a base-level
              athleticism that could put us on a path to <i>almost</i> believing
              he's physically capable.
            </p>
            <p>
              At one point he tries to break a window with a trashcan, and it
              looks like a pudgy toddler heaving a tonka truck from a playpen.
              When the window obviously doesn't break, he goes to shoot it - but
              his gun's safety is on, proving yet again that this guy is about
              as <span className="green">deadly as a daisy.</span>
            </p>
            <p>
              Despite having no skill, he manages to{" "}
              <span className="green">blindly fire</span> and somehow mortally
              wound Vincent because he's the 'good guy'. Fuckin' lame.
            </p>
            <h2>4.) TIE: Rey and Kylo Ren - The Force Awakens</h2>
            <div className="image-and-caption">
              <img src={Rey} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption">What's this big silly glow stick thingy?</p>
            </div>
            <p>
              Speaking of Daisies, I was originally going to rag on Rey alone. I
              hadn't seen The Force Awakens since 2015, and my recollection was
              that Rey, a &nbsp;
              <span className="green">
                sand-sledding dumpster-diver with no skills beyond rummaging
                through scrap metal
              </span>
              , managed to beat Kylo Ren without ever having seen a lightsaber
              or trained a single day with the force (WHAT'S THAT?!). Luke at
              least had a crash-course from Obi-Wan, whereas Rey had Obi-None.
            </p>

            <p>
              While it's true that Rey had no skills and didn't deserve to win,
              upon re-watch I realized{" "}
              <span className="green">
                Kylo Ren didn't deserve to win either
              </span>
              . Fuckin' dude stinks. He's a little crybaby that throws temper
              tantrums whenever he fucks up.
            </p>
            <div className="image-and-caption">
              <img src={Kylo} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption">And he fucks up ALL THE TIME</p>
            </div>
            <p>
              If he wasn't such an incompetent boob, there'd be no reason to get
              angry. It almost begs the question:{" "}
              <span className="green">how is this guy so shitty?</span> He
              trained under Luke Skywalker and was hand-picked to be Supreme
              Leader Snokes' apprentice - yet he gets dominated by Rey, and even
              allows Finn to land a lightsaber strike. With his long slimy face
              and nitwit capability, he's arguably the dumbest Star Wars
              character ever.
            </p>
            <div className="image-and-caption">
              <img
                src={Binks}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Meesa Tard-Tard Binks</p>
            </div>
            <p>
              Against all odds, he's still not the biggest loser in The Force
              Awakens. That award goes to Captain Phasma. What a{" "}
              <Link to="/mostdisappointing" className="article-link">
                complete waste
              </Link>
              .
            </p>
            <h2>3.) Aldo - Inglourious Basterds</h2>
            <div className="image-and-caption">
              <img src={Aldo} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption"></p>
            </div>
            <p>
              Aldo Raine is a badass. Leading a group of soldiers behind enemy
              lines to eliminate the forces of tyranny and genocide is heroic,
              and there's no cause more noble. He is unquestionably a formidable
              opponent. But when it comes to sheer intellect, Aldo's IQ is like
              a pinch of snuff to Hans Landa's full pipe.
            </p>

            <div className="image-and-caption">
              <img
                src={Landa}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                How kind. Your intellectual validation means everything to me.
              </p>
            </div>
            <p>
              Landa is the maestro of cunning - pulling the strings with the
              <span className="green"> precision of a surgeon</span>, and always
              one step ahead. He's got the game figured out before anyone's even
              opened the rulebook, and he can recite the by-laws in 4 languages.
              This is not a guy to be deceived or outsmarted.
            </p>
            <p>
              Aldo, on the other hand, is a bit of a{" "}
              <span className="green">buffoon</span>; a blunt instrument; like a
              bat used for bashing skulls in. He 'speaks Italian' like a
              survivor of the Bear Jew. Aside from the scalping scene, he never
              shows himself to be that bright.
            </p>
            <div className="image-and-caption">
              <img
                src={Aldo2}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Grazie</p>
            </div>
            <p>
              The movie theater scene perfectly embodies the gulf between Aldo
              and Landa's abilities. Aldo plans to torch the theater and take
              out the high command, and he thinks it could actually work. But
              Hans did some planning of his own and immediately puts the
              Basterd's scheme on ice. Adding insult to injury, he laughs
              hysterically in their face for the utter stupidity of their plan -
              and there's not a damn thing they can do about it.
            </p>

            <p>
              As a Nazi, Landa absolutely deserved to get a swastika carved into
              his head - that's getting off light. But in terms of who deserved
              to win based on skill, Aldo surely did not.
            </p>
            <h2>2.) Vincent - Heat</h2>
            <div className="image-and-caption">
              <img
                src={Pacino}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Yes, my refrigerator is running</p>
            </div>

            <p>
              From mass murderers to SS Colonels, the 'good guys' in this lineup
              face no shortage of morally reprehensible antagonists. But in
              'Heat',{" "}
              <span className="green">
                Pacino is a bigger piece of shit than De Niro
              </span>
              . He's a coke-fueled tornado in a topcoat, storming from crime
              scene to crime scene and barking disjointed sentence fragments
              like an auctioneer with Tourette's.
            </p>
            <div className="image-and-caption">
              <img
                src={Pacino2}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Whattayou ... TALKIN ABOOOUUTT??!!</p>
            </div>
            <p>
              The fact that Vincent is a{" "}
              <span className="green">slime-bucket</span> doesn't factor into
              his 'unworthy of winning' designation - that comes from his lack
              of skill alone. When he's not endangering informants, threatening
              civilians outside his jurisdiction, or abandoning his THIRD wife
              in the ER after their step daughters suicide attempt, he manages
              to get a little police work in.
            </p>
            <p>
              De Niro's 'Neil McCauley', meanwhile, is a relatively{" "}
              <span className="green">upstanding guy</span>. He doesn't want
              anyone to die needlessly. He's principled. Extremely disciplined.
              He knows the police response times and always finds the best
              escape routes. He's an effective leader that inspires unwavering
              loyalty from his men. Alas, as the 'bad guy', his brilliant plans
              are destined to be foiled, no matter how improbable.
            </p>

            <div className="image-and-caption">
              <img
                src={PacinoGun}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                An active robbery that takes 3 minutes? I'm an hour away but
                i'll be there in 83 seconds
              </p>
              <p>
                Neil outsmarted Vincent literally{" "}
                <span className="green">
                  every single time they had an encounter.
                </span>{" "}
                The shipyard scene is a particularly striking example of how
                effortless is was for De Niro to outclass Pacino.
              </p>
              <p>
                Final thought: There's absolutely no chance Vincent could've a.)
                got to Neil's location and spotted him in a huge crowd of
                people, and b.) caught up to him in a footrace while carrying an
                enormous shotgun. But you know what they say - plot armor is
                thicker than kevlar.
              </p>
            </div>
            <h2>1.) Nick - Law Abiding Citizen</h2>
            <div className="image-and-caption">
              <img
                src={Foxx2}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Cool gloves</p>
            </div>

            <p>
              Just like that unnecessary extra 'X' in Jamie's last name, we're
              needlessly doubling up on Mr. Foxx and his undeserving winner
              roles. In the top spot, we have Law Abiding Citizen - a movie
              where he definitely didn't deserve to win. In fact,{" "}
              <span className="green">he's the villain.</span>
            </p>
            <p>
              To begin, he shirks his duty by cutting a self-serving deal with
              cold-blooded murderers to boost his conviction rate - thus
              beginning the domino effect that spirals into Clyde's rage.
              Afterwards when Clyde is in Jail, he does everything in his power
              to deny Clyde's requests - even the simple ones like getting a new
              bed - all because he's an egomaniac.
            </p>
            <div className="image-and-caption">
              <img
                src={LawAbiding}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Oh, you want sugar in that coffee? Fuck you
              </p>
            </div>
            <p>
              It takes about 17 losses back-to-back before Nick realizes he
              <span className="green"> cannot outsmart Clyde</span>. So he does
              what any legal professional bound by a code of ethics would do,
              and breaks into Nick's property while spitefully exclaiming "fuck
              his civil rights!".
            </p>
            <p>
              {" "}
              He doesn't use the (unlawfully obtained) evidence to ensure Clyde
              faces the appropriate legal consequences for his actions. He
              instead traps him and{" "}
              <span className="green">LIGHTS HIM ON FIRE</span>.
            </p>
          </div>{" "}
          <Link to="/morpheus">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Morpheus, the Pimp</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Undeserving Winners"
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/fakemovies">
            <div className="image-and-caption">
              <img
                src={koogler}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Fake Movies We Want to See</p>
            </div>
          </Link>
          <Link to="/bestzingers">
            <div className="image-and-caption">
              <img
                src={Zingers}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">
                Best zingers from minor characters
              </p>
            </div>
          </Link>
          <Link to="/toofarpee">
            <div className="image-and-caption">
              <img
                src={TooFar2Pee}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Dudes who went too far to pee</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default UndeservingWinners;
