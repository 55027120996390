import React from "react";
import FSLogoNew from "./pictures/FSLogo/slobs-new.png";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <div>
      <div>
        <h1 className="contactText">
          Got <span className="green">something</span> to say?
        </h1>
      </div>
      <div className="contactLogoContainer">
        <img src={FSLogoNew} alt={"FilmSlobs Logo"}></img>
      </div>

      <div className="contactPageEmail">
        <p>
          Send us an email:{" "}
          <span>
            <a href="mailto:contact@filmslobs.com" style={{ color: "#04abed" }}>
              contact@filmslobs.com
            </a>
          </span>
        </p>
        <br></br>
        <Link to="/terms">
          <p className="termsLink" style={{ marginBottom: "50px" }}>
            TERMS AND CONDITIONS
          </p>
        </Link>
      </div>
    </div>
  );
}

export default Contact;
