import React from "react";
import GambolMain from "../topfive-pictures/gambol-main.jpeg";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import OJ from "../grabbag-pictures/oj-simpson.webp";
import Shirley from "../grabbag-pictures/nielsen-shirley.jpeg";
import SpinalTap from "../grabbag-pictures/spinaltap.jpg";
import BlazingSaddles from "../grabbag-pictures/blazing-saddles.webp";
import ScaryMovie from "../grabbag-pictures/scarymovie-4.jpg";
import arabia from "../../src/coldtake-pictures/arabia.jpeg";
import NotificationBell from "../NotificationBell";
import Zingers from "../pictures/topfive/zingers.jpeg";

function Spoofs() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingGrabbag">
          <span className="underline">Grab Bag</span>
        </Link>{" "}
        &gt; What Happened to Spoofs?
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>What Happened to Spoofs?</p>
          </div>
          <div className="published">PUBLISHED Oct 6th, 2024</div>
          <div className="authorName">
            BY{" "}
            <p>
              {" "}
              <Link to="/scotland">
                <span className="blue" id="author-name">
                  Scotland
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! What Happened to Spoofs?"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={Shirley}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">I am serious. And don't call me Shirley.</p>
            </div>
            <p>
              Flying at 35,000 feet an impeccably dressed young Caucasian boy,
              no older than 10, approaches a row of seats with a tray of
              coffees. A young Caucasian girl of the same age is sitting on the
              window side giving off an air of precociousness. Beside her is an
              empty seat on the aisle side.
            </p>
            <p>
              “Excuse me, I happened to be passing, and I thought you’d might
              like some coffee” the boy proposes, his tone and manner well
              beyond his years.
            </p>
            <p>
              {" "}
              “Oh, that’s very nice of you” the girl replies, “won’t you sit
              down?” The interaction is reminiscent of a Jane Austin novel.
            </p>
            <p>“Cream?” the boy offers.</p>
            <p>
              “No thank you” replies the girl before nonchalantly continuing. “I
              take my coffee black, like my men.”
            </p>
            <p>
              {" "}
              If there’s a person in this world who doesn’t have a favorite line
              from the 1980 smash hit <em>Airplane!</em>, I haven’t met them
              yet. There are people across generations who still cite this as
              maybe the funniest movie of all time, with its great mix of
              slapstick, wit and absurdity that has pretty much anyone who
              watches it gasping for air for a full 90 minutes. Not so healthy,
              you might say, but anyone who has ever watched the movie has come
              away a better person for it.
            </p>
            <div className="image-and-caption">
              <img src={OJ} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption">OK, maybe not OJ Simpson</p>
            </div>
            <p>
              Now this was right at a time when the spoof movie was in vogue.
              Six years before <em>Airplane!</em> came out saw the release of{" "}
              <em>Blazing Saddles</em>, an epically funny satire of the Western
              genre that is remembered by most for the scene where a group of
              cowboys is sitting around a fire eating beans and farting for a
              solid 30 seconds. That flatulent symphony is etched onto my brain
              for eternity, and I don’t think I’m alone.
            </p>
            <div className="image-and-caption">
              <img
                src={BlazingSaddles}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">'The Fartissimo Fugue' in B-Flat(ulence)</p>
            </div>
            <p>
              The co-director of <em>Airplane!</em> was Jim Abrahams and the
              director of
              <em>Blazing Saddles</em> was Mel Brooks. Between the mid-70s and
              the mid-90s, the two directors were responsible for, in no
              particular order (except maybe personal preference),{" "}
              <em>
                The Naked Gun, Young Frankenstein, Hot Shots!, Robin Hood: Men
                in Tights, Spaceballs
              </em>{" "}
              and a bunch more. This was undoubtedly the golden age of spoof.
            </p>
            <p>
              I know what you’re thinking; “OK, so spoofs are great. Everybody
              loves them. So what? Why am I wasting my time reading this
              article?” It’s a fair question. I don’t intend to write a eulogy
              to the genre. Far from it. I hope the genre makes a grand
              reappearance even more satisfying than Leslie Nielsen turning up
              in <em>Scary Movie 3</em>. The point is that since the golden age,
              not many writers, directors or producers have been keen to carry
              the torch of the spoof genre.
            </p>
            <div className="image-and-caption">
              <img
                src={ScaryMovie}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">They will, however, carry a lighter</p>
            </div>
            <p>
              There are notable mentions. The Wayans Brothers gave us the{" "}
              <em>Scary Movie</em> franchise and the ludicrously long titled{" "}
              <em>
                Don’t be a Menace to South Central while Drinking Your Juice in
                the Hood
              </em>
              . The last good spoof movie I can remember was{" "}
              <em>Tropic Thunder</em>, and that got a release in 2008. Maybe
              with the parodying of Hollywood itself <em>Tropic Thunder</em>{" "}
              went too meta and blew the whole genre up. Other spoofs since the
              turn of the century have either been flops or targeted too
              narrowly at one particular movie, or both like
              <em> Superfast!</em> In 2015.
            </p>
            <p>
              I wonder what’s next for spoofs. Is it a hiatus? Is it a demise?
              Don’t you find the absence of good parody movies strange in this
              age of Disney owning Star Wars and new Marvel movies being
              released every 13 hours? <em>Superhero Movie</em> bombed, but that
              shouldn’t be a nail in the coffin for lampooning that particular
              type of film. You’ll be glad to know that both Jim Abrahams and
              Mel Brooks are still alive, and the Wayans brothers are around
              somewhere. There’s hope yet.
            </p>
            <p>
              Footnote: I fucking can’t believe I forgot to mention{" "}
              <em>This is Spinal Tap</em> through the whole article!
            </p>
            <div className="image-and-caption">
              <img
                src={SpinalTap}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Clearly my brain was only turned up to 10
              </p>
            </div>
          </div>{" "}
          <Link to="/footballslobs">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Football: A Fantasy Life</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! What Happened to Spoofs?"
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/bestzingers">
            <div className="image-and-caption">
              <img
                src={Zingers}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">
                Best Zingers from Minor Characters
              </p>
            </div>
          </Link>
          <Link to="/dramaischeap">
            <div className="image-and-caption">
              <img
                src={arabia}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Drama is cheap</p>
            </div>
          </Link>
          <Link to="/mostdisappointing">
            <div className="image-and-caption">
              <img
                src={GambolMain}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Most disappointing badasses</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Spoofs;
