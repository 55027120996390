import React from "react";
import Tshirt from "../src/pictures/FSLogo/FS-tshirt.jpeg";

function Merch() {
  return (
    <div>
      <div>
        <h1>Merch</h1>
      </div>

      <div className="merchRowOneContainer">
        <div className="merchCategories">
          <p>
            <b>Adult Apparel</b>
          </p>
          <p className="merchTypes">T-shirts</p>
          <p className="merchTypes">Tank Tops</p>
          <p className="merchTypes">Hoodies</p>
          <p className="merchTypes">Baseball Shirts</p>
          <p className="merchTypes">Long Sleeve Shirts</p>
        </div>
        <div>
          <img className="merchRowOne" src={Tshirt} alt={"tshirt"}></img>
          <p className="merchDescription">Filmslobs Logo T-shirt</p>
          <p className="merchPrice">$20</p>
          <button className="merchButton">Select options</button>
        </div>
        <div>
          <img className="merchRowOne" src={Tshirt} alt={"tshirt"}></img>
          <p className="merchDescription">Filmslobs Logo T-shirt</p>
          <p className="merchPrice">$20</p>
          <button className="merchButton">Select options</button>
        </div>
        <div>
          <img className="merchRowOne" src={Tshirt} alt={"tshirt"}></img>
          <p className="merchDescription">Filmslobs Logo T-shirt</p>
          <p className="merchPrice">$20</p>
          <button className="merchButton">Select options</button>
        </div>
      </div>
      <div className="merchRowOneContainer">
        <div className="merchCategories2"></div>
        <div>
          <img className="merchRowOne" src={Tshirt} alt={"tshirt"}></img>
          <p className="merchDescription">Filmslobs Logo T-shirt</p>
          <p className="merchPrice">$20</p>
          <button className="merchButton">Select options</button>
        </div>{" "}
        <div>
          <img className="merchRowOne" src={Tshirt} alt={"tshirt"}></img>
          <p className="merchDescription">Filmslobs Logo T-shirt</p>
          <p className="merchPrice">$20</p>
          <button className="merchButton">Select options</button>
        </div>{" "}
        <div>
          <img className="merchRowOne" src={Tshirt} alt={"tshirt"}></img>
          <p className="merchDescription">Filmslobs Logo T-shirt</p>
          <p className="merchPrice">$20</p>
          <button className="merchButton">Select options</button>
        </div>
      </div>
    </div>
  );
}

export default Merch;
