import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MovieCard from "./MovieCard";
import SimpleModal from "./NotReviewedMovieDetails";
import TVList from "./TVList";
import NoResults from "./NoActors";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ScrollArrow from "./ScrollArrow";
import InputLabel from "@mui/material/InputLabel";

function TVRatings() {
  const [search, setSearch] = useState("");
  const [sortOption, setSortOption] = useState("Original");
  const [selectedGenre, setSelectedGenre] = useState("All TV Shows");

  const lastUpdatedDate = "February 15th, 2025";

  useEffect(() => {}, [selectedGenre]);

  const genres = [
    ...new Set([
      "All TV Shows",
      ...TVList.map((show) => show.genre),
      ...TVList.map((show) => show.subgenre).filter(Boolean), // Ensure subgenres are included
    ]),
  ].sort((a, b) => (a === "All TV Shows" ? -1 : a.localeCompare(b)));

  const handleGenreChange = (event) => {
    setSelectedGenre(String(event.target.value));
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const getSortedShows = () => {
    let sortedShows = [...TVList];

    if (sortOption === "Alphabetical") {
      sortedShows.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOption === "Chronological") {
      sortedShows.sort((a, b) => b.year - a.year);
    } else if (sortOption === "Newest") {
      return sortedShows.slice(-5);
    }

    return sortedShows;
  };

  const ratedShows = getSortedShows().filter(
    (show) => show.rating !== undefined
  );

  const filteredShows = ratedShows.filter((show) => {
    const lowerCaseSearch = search.toLowerCase();

    const matchesSearch =
      show.name.toLowerCase().includes(lowerCaseSearch) ||
      (show.director &&
        show.director.toLowerCase().includes(lowerCaseSearch)) ||
      (show.cast &&
        show.cast
          .toLowerCase()
          .split(", ")
          .some((actor) => actor.includes(lowerCaseSearch)));

    const matchesGenre =
      selectedGenre === "All TV Shows" ||
      show.genre === selectedGenre ||
      show.subgenre === selectedGenre; // ✅ Ensure filtering includes subgenres

    return matchesSearch && matchesGenre;
  });

  return (
    <div>
      <body className="searchBody">
        <div>
          <div className="searchAndGenre">
            <div className="genreSign">
              {sortOption === "Newest" ? (
                <>
                  NEWEST REVIEWS
                  <p className="newReviewDate">
                    Last updated on {lastUpdatedDate}
                  </p>
                </>
              ) : selectedGenre && selectedGenre !== "All TV Shows" ? (
                selectedGenre.toUpperCase()
              ) : (
                "ALL TV SHOWS"
              )}
            </div>

            <div className="searchAndFiltersContainer">
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                className="searchInput"
                placeholder="Type a TV show"
                onChange={(e) => setSearch(e.target.value)}
              />

              <div className="filterContainer">
                <FormControl component="fieldset" className="filter-group">
                  <FormLabel component="legend">Sort By</FormLabel>
                  <RadioGroup
                    row
                    name="filter-options"
                    value={sortOption}
                    onChange={handleSortChange}
                  >
                    <FormControlLabel
                      value="Original"
                      control={<Radio />}
                      label="Default"
                    />
                    <FormControlLabel
                      value="Alphabetical"
                      control={<Radio />}
                      label="A-Z"
                    />
                    <FormControlLabel
                      value="Chronological"
                      control={<Radio />}
                      label="Year &#x2191;&#x2193;"
                    />
                    <FormControlLabel
                      value="Newest"
                      control={<Radio />}
                      label="Newest"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl variant="standard" className="genre-dropdown">
                  <InputLabel>Filter by Genre</InputLabel>
                  <Select
                    value={selectedGenre}
                    onChange={handleGenreChange}
                    displayEmpty
                  >
                    <MenuItem
                      value="All TV Shows"
                      style={{ fontWeight: "bold" }}
                    >
                      All TV Shows
                    </MenuItem>

                    {genres
                      .filter((genre) => genre !== "All TV Shows")
                      .map((genre) => (
                        <MenuItem key={genre} value={genre}>
                          {genre}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <ScrollArrow />

          <div className="renderContainer">
            {filteredShows.length > 0 ? (
              filteredShows.map((show) =>
                show.synopsis && show.synopsis.trim() !== "" ? (
                  <MovieCard key={show.id} {...show} />
                ) : (
                  <div className="movieContainer" key={show.id}>
                    <SimpleModal
                      name={show.name}
                      poster={show.poster}
                      rating={show.rating}
                      year={show.year}
                      runtime={show.runtime}
                      genre={show.genre}
                      subgenre={show.subgenre}
                      {...(show.rated && show.rated.trim() !== ""
                        ? { rated: show.rated }
                        : {})}
                    />
                    <div className="movieTitleCard" title={show.name}>
                      <div className="movieTitleText">
                        {show.name} ({show.year})
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <NoResults />
            )}
          </div>
        </div>
      </body>
    </div>
  );
}

export default TVRatings;
