import React from "react";

function RecipeCard(props) {
  return (
    <div className="recipeContainer">
      <div className="recipeIngredients">Ingredients: {props.drinkRecipe}</div>
      <div className="relatedFlex"></div>
    </div>
  );
}

export default RecipeCard;
