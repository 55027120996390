import React from "react";
import { Link } from "react-router-dom";

function WritingColdTakes() {
  return (
    <div>
      <div>
        <div className="writingPageDescription">
          <h2>
            <Link to="writingtopFive">
              {" "}
              <span className="chevron left desktop"></span>
            </Link>
            <span className="green">Cold Takes</span>
            <Link to="writingGrabBag">
              <span className="chevron right desktop"></span>
            </Link>
          </h2>
          <p className="writingPageSubheader">Your weekly dose of negativity</p>
          <div className="mobileChevronsArticlesPage">
            <Link to="writingtopFive">
              {" "}
              <span className="chevron left"></span>
            </Link>
            <Link to="writingGrabBag">
              <span className="chevron right"></span>
            </Link>
          </div>
        </div>
        <div className="latestContainer">
          <p className="latest">Latest</p>
        </div>
        <div className="grid-container">
          <Link to="dramaischeap">
            <div class="grid-item twenty">
              {" "}
              <p className="articlesBackground">Drama is Cheap</p>
            </div>
          </Link>
          <Link to="community">
            <div className="grid-item sixteen">
              <p className="articlesBackground">Lamest Community Episode</p>
            </div>
          </Link>
          <Link to="johnny">
            <div class="grid-item seventeen">
              {" "}
              <p className="articlesBackground">
                Johnny Utah Is A Piece of Shit
              </p>
            </div>
          </Link>

          <Link to="oldnotgood">
            <div class="grid-item eighteen">
              {" "}
              <p className="articlesBackground">Old ≠ Good</p>
            </div>
          </Link>
          <Link to="undeserving">
            <div class="grid-item twentyfour">
              <p className="articlesBackground">Undeserving Winners</p>
            </div>
          </Link>
          <Link to="morpheus">
            <div class="grid-item twentynine">
              <p className="articlesBackground">Morpheus, the Pimp</p>
            </div>
          </Link>
          <Link to="hollywoodruinedbond">
            <div class="grid-item thirtytwo">
              <p className="articlesBackground">
                How Hollywood Ruined James Bond
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default WritingColdTakes;
