import React from "react";
import { Link } from "react-router-dom";

function WritingTopFive() {
  return (
    <div>
      <div>
        <div className="writingPageDescription">
          <h2>
            <Link to="writingGrabBag">
              {" "}
              <span className="chevron left desktop"></span>
            </Link>

            <span className="green">Top 5 Countdowns</span>
            <Link to="writingColdTakes">
              <span className="chevron right desktop"></span>
            </Link>
          </h2>
          <p className="writingPageSubheader">
            Definitive lists which are ever-changing
          </p>
          <div className="mobileChevronsArticlesPage">
            {" "}
            <Link to="writingGrabBag">
              {" "}
              <span className="chevron left"></span>
            </Link>
            <Link to="writingColdTakes">
              <span className="chevron right"></span>
            </Link>
          </div>
        </div>
        <div className="latestContainer">
          <p className="latest">Latest</p>
        </div>

        <div className="grid-container">
          <Link to="bestzingers">
            <div class="grid-item three">
              {" "}
              <p className="articlesBackground">
                Zingers from Minor Characters
              </p>
            </div>
          </Link>

          <Link to="fakemovies">
            <div class="grid-item four">
              {" "}
              <p className="articlesBackground">Fake Movies We Want to See</p>
            </div>
          </Link>

          <Link to="biggestguns">
            <div class="grid-item five">
              {" "}
              <p className="articlesBackground">
                Biggest Guns on Movie Posters
              </p>
            </div>
          </Link>

          <Link to="raresequelwins">
            <div class="grid-item two">
              {" "}
              <p className="articlesBackground">Rare Sequel Wins</p>
            </div>
          </Link>

          <Link to="toofarpee">
            <div class="grid-item one">
              {" "}
              <p className="articlesBackground">
                Dudes Who Went Too Far to Pee
              </p>
            </div>
          </Link>

          <Link to="mostdisappointing">
            <div className="grid-item six">
              <p className="articlesBackground">Most Disappointing Badasses</p>
            </div>
          </Link>

          <div class="grid-item eight">
            <div className="comingSoon">COMING SOON</div>
            <p className="articlesBackground"></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WritingTopFive;
