import React from "react";
import gremlins1 from "../../src/pictures/sequel-wins/gremlins-2.jpeg";
import maverick from "../../src/pictures/sequel-wins/maverick.webp";
import vader from "../../src/pictures/sequel-wins/vader.jpeg";
import troopers from "../../src/pictures/sequel-wins/troopers.jpeg";
import money from "../../src/pictures/sequel-wins/money.jpeg";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import arabia from "../../src/coldtake-pictures/arabia.jpeg";
import Boozecruize from "../../src/grabbag-pictures/BC_TN2.jpg";
import odevideo from "../grabbag-pictures/odethumbnail-black.jpeg";
import NotificationBell from "../NotificationBell";

function RareSequelWins() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingtopFive">
          <span className="underline">Top 5 Lists</span>
        </Link>{" "}
        &gt; Rare Sequel Wins
      </div>
      <div className="articleContentContainer">
        {" "}
        <div class="article-container">
          <div class="article-container-header">
            <p>Rare Sequel Wins</p>
          </div>
          <div className="published">PUBLISHED Jul 17, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/dean">
                <span className="blue" id="author-name">
                  Dean
                </span>
              </Link>
            </p>{" "}
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Top 5 Rare Sequel Wins"
              }
            />
          </div>
          <div class="article-container-body">
            <p>
              Sequels can be a lot like Jäger shots - they seem like a good idea
              at the time, but the more you have, the worse it gets. We've all
              seen sequels so bad they make the original look like Oscar
              material (hey there, Teen Wolf Too). But once in a blue moon they
              get it right - so right, in fact, that you might just argue the
              sequel is even better than the original. And we've got five of 'em
              right here.
            </p>

            <h2>5.) The Color of Money</h2>
            <div className="image-and-caption">
              <img
                src={money}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Do NOT leave Paul Newman alone with your girlfriend.
              </p>
            </div>
            <p>
              Let's face it. Aside from the first pool scene,{" "}
              <span className="green">The Hustler sucked.</span> It's a boring,
              melodramatic slog centered around a tragic romance that no one
              cares about.
            </p>
            <p>
              The Color of Money is everything The Hustler{" "}
              <em>should have been</em>. Slick billiards action, fortunes
              changing hands, and scamming babes. It only took 25 years to get
              it right.{" "}
            </p>

            <h2>4.) Gremlins 2</h2>
            <div className="image-and-caption">
              <img
                src={gremlins1}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Never understood why their fingers became so penis-y in the
                sequel.
              </p>
            </div>
            <p>
              Gremlins was great. It had that cozy suburban vibe we all know and
              love from Amblin, with a twist. Sure, there were bloodthirsty
              little monsters running around wreaking havoc, but we still had
              faith that good would come out on top.
            </p>
            <p>
              The New Batch flips that innocence on its ear. Gone is that
              wholesome small-town vibe, replaced by a wild, crazy, late 80s New
              York City - think coke-fueled chaos and drunken debauchery. The
              gremlins went from mischievous to downright scandalous with their
              smoking, flashing, and sluttiness.
            </p>

            <h2>3.) Top Gun: Maverick</h2>
            <div className="image-and-caption">
              <img
                src={maverick}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Traffic cam footage from the Highway to the Danger Zone
              </p>
            </div>
            <p>
              This movie had everything to lose. During the 36 years since the
              original, the political climate radically changed, COVID gripped
              the world, and movie theaters were on life support. If Top Gun:
              Maverick failed, it not only would've tarnished one of the most
              iconic movies of all time; it would've been the final rasping
              croak of movies theaters.
            </p>
            <p>
              Instead, it kicked on the afterburners and went streaking into the
              stratosphere. Mixing a perfect blend of story, action, and
              nostalgia - while avoiding political bullshit - proved to be the
              recipe everyone was clamoring for. THE hit of summer 2022.
            </p>

            <h2>2.) The Empire Strikes Back</h2>
            <div className="image-and-caption">
              <img
                src={vader}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Disney force gripping every cent they can.
              </p>
            </div>
            <p>
              Following up literally the biggest movie of all time, Empire {""}{" "}
              <em>definitely</em> Strikes Back. Return of the Jedi is a solid
              Part III but Empire is the best Star Wars movie, Hans down.
            </p>
            <p>
              We get immediate action, a romance that doesn't become a drawn-out
              love story, and even the potential death of a main character. We
              see less whiney-ass Luke and much more Solo. Billy D smooths
              everyone’s heart and soul, and we’re privileged to one of the most
              iconic improvised lines in movie history (that {""}
              <em>I know</em> of). We also get a more nuanced Vader who shows
              off his most impressive laser blocks.
            </p>
            <p>
              Empire Strikes Back is not only a perfect follow-up, but it
              could've been a great standalone film.
            </p>
            <h2>1.) Super Troopers 2</h2>
            <div className="image-and-caption">
              <img
                src={troopers}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Farva needs some Flova Scotia to take the edge off
              </p>
            </div>
            <p>
              Much like Top Gun: Maverick, this sequel had the potential to
              crash and burn. The original Super Troopers is such a beloved
              touchstone of comedy that if Broken Lizard didn't bring the heat,
              hardcore fans would've gone ballistic. It was also made nearly 20
              years later (crowdfunded, no less!) so the chances of success
              looked shaky.
            </p>
            <p>
              Luckily, our heroes did not (club) dread the potential
              repercussions of a flop. They cracked some beer (fest), broke out
              the typewriters, and made a slammin (salmon) sequel. The whole
              flick is hilarious, and the America vs. Canada jokes are goddamn
              hysterical.
            </p>
            <p>
              <strong>Honorable Mentions: </strong> Terminator 2, Zombieland 2,
              Paddington 2
            </p>
          </div>
          <Link to="/toofarpee">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Dudes Who Went Too Far to Pee</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Top 5 Rare Sequel Wins"
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/boozecruize">
            <div className="image-and-caption">
              <img
                src={Boozecruize}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Ride the booze cruize</p>
            </div>
          </Link>
          <Link to="/oldnotgood">
            <div className="image-and-caption">
              <img
                src={arabia}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Old ≠ Good</p>
            </div>
          </Link>
          <Link to="/odevideostore">
            <div className="image-and-caption">
              <img
                src={odevideo}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Ode to the video store</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RareSequelWins;
