import React from "react";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import Odyssey from "../coldtake-pictures/tvtime.jpeg";
import Babe from "../coldtake-pictures/baymovie.jpg";
import Dark from "../coldtake-pictures/dark.jpg";
import Old from "../coldtake-pictures/Mokeinternet.png";
import Modelt from "../coldtake-pictures/model-t.jpeg";
import odevideo from "../grabbag-pictures/odethumbnail-black.jpeg";
import Utah from "../../src/coldtake-pictures/johnnyutah.jpeg";
import dramaischeap from "../../src/grabbag-pictures/drama.jpeg";
import NotificationBell from "../NotificationBell.js";

function OldNotGood() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingColdTakes">
          <span className="underline">Cold Takes</span>
        </Link>{" "}
        &gt; Old ≠ Good
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Old Does Not Equal Good</p>
          </div>
          <div className="published">PUBLISHED Aug 3, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/dean">
                <span className="blue" id="author-name">
                  Dean
                </span>
              </Link>
            </p>
            <ShareCircle
              description={"Check out this article from FilmSlobs! Old ≠ Good"}
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={Odyssey}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Gee, I hope this scene lasts 20 minutes.</p>
            </div>
            <p>
              We've all met this douche. The self-appointed curator of all
              things cinema, whose tastes are so refined and esoteric that they
              can't possibly enjoy anything that's not in black and white or
              made before they were born. They'll spend hours droning on about
              French New Wave or the Italian neorealism movement, but couldn't
              tell you the last time they actually enjoyed a movie. And let's
              not forget the obligatory corduroy jacket - because nothing
              screams "I'm better than you" quite like wearing a fabric that
              feels like a cat's tongue.{" "}
              <span className="green">
                To this insufferable turd, we say: shove it.
              </span>
            </p>

            <p>
              Old does not <span className="green">automatically</span> equal
              good - in fact, it usually means
              <span className="green"> bad</span>. We don't dispute that old
              movies were terrific or groundbreaking <em>for their time</em> -
              but trying to argue they hold up by today's standards is like
              insisting a Model-T can compete with a modern sedan.
            </p>
            <div className="image-and-caption">
              <img
                src={Modelt}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "Oh, you have <em>airbags?</em> How bourgeois."
              </p>
            </div>
            <p>
              For any film snobs out there, you're undoubtedly seething right
              now. How <strong>dare</strong> we have the audacity to even{" "}
              <em>suggest</em> that old films are anything less than sacrosanct.
              We can already hear you gnashing your translucent teeth and
              poison-penning your response -- some tired horseshit like, "what,
              do you need huge explosions and dizzying levels of violence and
              nudity just to enjoy a movie?". Nope. Don't need those things. But
              what we <em>do </em>
              need is{" "}
              <span className="green">dialogue or story advancement</span> - not
              five more minutes of watching wind chimes clang.
            </p>
            <div className="image-and-caption">
              <img src={Babe} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption">
                Explosions and nudity <em>are</em> outstanding, though.
              </p>
            </div>
            <p>
              Before we wade any deeper into this (cess)pool, we'll extend this
              <span className="green"> olive branch:</span> <em>some</em> old
              movies are freakin' awesome. Take 'The Great Escape'. Who doesn't
              tap their foot to the theme song or get a semi-chub watching Steve
              McQueen jump that fence on a motorcycle? It's got heart, suspense,
              and the storytelling is timeless. In addition, the plot is always
              moving forward - unlike most old movies which are excruciatingly
              slow.
            </p>
            <p>
              Consider Lawrence of Arabia. It's about 372 hours long and
              comprised of 60% landscape shots, 40% orchestra, and despite being
              incredibly boring, it won't put you to sleep. Right when you're
              about to drift off, screams from desert people or ear-shattering
              musical scores explode through the speakers.
            </p>
            <p>
              In today's world it's flat out unacceptable to have wide shots for
              minutes at a time with no dialogue - it's like watching paint dry.
              <span className="green">
                {" "}
                But in 1940, watching paint dry was the literal alternative to
                seeing a movie, so it makes sense.
              </span>{" "}
              People didn't have didn't have high-powered computers in their
              pockets that could contact anyone in the world, answer any
              question ever, or render thousands of nudie pictures in a second.
            </p>
            <div className="image-and-caption">
              <img src={Old} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption">
                "Everything here is a click away on the internet."
              </p>
            </div>
            <p>
              In 1968, audiences were enthralled with
              <em> 2001: A Space Odyssey</em> and the endless scenes of beeping
              and booping through the universe as nothing happens. They probably
              also loved how
              <em> Once Upon A Time in the West </em>
              opened with{" "}
              <span className="green">
                ten minutes of tumbleweeds and a guy blowing flies off his face.
              </span>{" "}
              We can't imagine how anyone enjoyed it <em>then</em>, let alone
              now.
            </p>
            <p>
              "Oh boy, honey! Another 30 minute sequence of silence! I'm on the
              edge of my seat."
            </p>
            <div className="image-and-caption">
              <img
                src={Dark}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "scale-down" }}
              />
              <p id="image-caption">This edge</p>
            </div>
            <p>
              But hey, maybe we're just not sophisticated enough to appreciate
              the subtle nuances of beeping and booping or the thrilling drama
              of tumbleweeds. Maybe we're missing out on some deeper meaning
              that only the true cinephiles can understand.
            </p>
            <p>
              {" "}
              Actually - <span className="green">
                screw the olive branch.
              </span>{" "}
              We've taken enough shit off people over the years. We really do
              try to enjoy old movies, but they're ultimately a test of patience
              instead of an enjoyable diversion. Calling them shitty and boring
              doesn't make us stupid or ADD (the go-to defense of every
              pretentious jerk), it makes us reasonable. We simply can admit
              when something sucks. Like most old movies.
            </p>
          </div>{" "}
          <Link to="/undeserving">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Undeserving Winners</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={"Check out this article from FilmSlobs! Old ≠ Good"}
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/odevideostore">
            <div className="image-and-caption">
              <img
                src={odevideo}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Ode to the video store</p>
            </div>
          </Link>
          <Link to="/dramaischeap">
            <div className="image-and-caption">
              <img
                src={dramaischeap}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Drama is cheap</p>
            </div>
          </Link>
          <Link to="/johnny">
            <div className="image-and-caption">
              <img
                src={Utah}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Johnny utah is a piece of shit</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OldNotGood;
