import React from "react";
import { Link } from "react-router-dom";

function WritingGrabBag() {
  return (
    <div>
      <div>
        <div className="writingPageDescription">
          <h2>
            <Link to="writingColdTakes">
              {" "}
              <span className="chevron left desktop"></span>
            </Link>
            <span className="green">Grab Bag</span>{" "}
            <Link to="writingtopFive">
              <span className="chevron right desktop"></span>
            </Link>
          </h2>
          <p className="writingPageSubheader">
            Like a mixing a six-pack with random topics.
          </p>
          <div className="mobileChevronsArticlesPage">
            <Link to="writingColdTakes">
              <span className="chevron left"></span>
            </Link>
            <Link to="writingtopFive">
              <span className="chevron right"></span>
            </Link>
          </div>
        </div>
        <div className="latestContainer">
          <p className="latest">Latest</p>
        </div>
        <div className="grid-container">
          <Link to="footballslobs">
            <div class="grid-item twentyeight">
              <p className="articlesBackground">FilmSlobs Fantasy Breakdown</p>
            </div>
          </Link>
          <Link to="odevideostore">
            <div className="grid-item fifteen">
              <p className="articlesBackground">Ode to the Video Store</p>
            </div>
          </Link>
          <Link to="hackiswack">
            <div class="grid-item twentyfive">
              <p className="articlesBackground">Hack is Wack</p>
            </div>
          </Link>
          <Link to="boozecruize">
            <div class="grid-item twentysix">
              {" "}
              <p className="articlesBackground">Ride the Booze Cruize</p>
            </div>
          </Link>
          <Link to="teachingtip">
            <div class="grid-item thirty">
              <p className="articlesBackground">Time-Tested Teaching Tip</p>
            </div>
          </Link>
          <Link to="spoofs">
            <div class="grid-item thirtyone">
              <p className="articlesBackground">What Happened to Spoofs?</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default WritingGrabBag;
