import React from "react";
import { Link } from "react-router-dom";
import ArticlesBy from "./ArticlesBy";
import SpencePic from "../src/pictures/author-pics/spenceDiamond.jpg";
import Bond from "../src/coldtake-pictures/bond-1.jpg";

import Fade from "react-reveal/Fade";

function Spence() {
  return (
    <div>
      <div className="aboutWriterContainer">
        <div className="writerThumbnail">
          <img
            src={SpencePic}
            className="writerThumbnailImage"
            alt="writer-headshot"
          ></img>
        </div>
        <div className="nameAndBlurbContainer">
          <div className="writerName">Spence Diamond</div>
          <div className="writerBlurb">
            Spence once had the option between a large or small beer, and he
            chose the small one. He will <em>never</em> live it down.
          </div>
        </div>
      </div>
      <div className="alsoWrittenByContainer">
        <p className="seeAlsoHeader">Articles by Spence</p>
        <Fade bottom>
          <Link to="/hollywoodruinedbond">
            <ArticlesBy
              picture={Bond}
              otherArticleTitle={"How Hollywood Ruined James Bond"}
              otherArticleSynopsis={
                "James Bond used to be fun. The movies were suave, absurd, full of ridiculous gadgets, explosive action, and razor-sharp one-liners. They were the kind of films where a guy in a tuxedo could drive an invisible car, bed a woman named Pussy Galore, and escape a volcano lair ..."
              }
            />
          </Link>
        </Fade>
      </div>
    </div>
  );
}

export default Spence;
