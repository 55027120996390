import React from "react";
import MovieCard from "./MovieCard";
import movieList from "./MovieList";

let reviewedMovies = movieList.slice(-5);

let items = reviewedMovies.map((movie) => {
  return (
    <MovieCard
      key={movie.id}
      name={movie.name}
      poster={movie.poster}
      rating={movie.rating}
      year={movie.year}
      synopsis={movie.synopsis}
      genre={movie.genre}
      subgenre={movie.subgenre}
      runtime={movie.runtime}
      pairs={movie.pairs}
      noPairs={movie.noPairs}
      drinkRecipe={movie.drinkRecipe}
      rated={movie.rated}
      picture1={movie.picture1}
      picture2={movie.picture2}
      picture3={movie.picture3}
      tagline={movie.tagline}
      taglineWriter={movie.taglineWriter}
      bulletOne={movie.bulletOne}
      bulletTwo={movie.bulletTwo}
      bulletThree={movie.bulletThree}
      cast={movie.cast}
    />
  );
});

function NewReview() {
  return (
    <div>
      <div className="employeePickContainer">
        <div className="genresign">
          <p style={{ height: "1rem" }}>LATEST REVIEWS</p>
          <p className="newReviewDate">Last updated February 19th, 2025</p>
        </div>
      </div>

      <div className="renderContainer">{items}</div>
    </div>
  );
}

export default NewReview;
