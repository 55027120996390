import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MovieCard from "./MovieCard";
import SimpleModal from "./NotReviewedMovieDetails";
import MovieList from "./MovieList";
import NoResults from "./NoActors";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ScrollArrow from "./ScrollArrow";
import InputLabel from "@mui/material/InputLabel";

function Ratings() {
  const [search, setSearch] = useState("");
  const [sortOption, setSortOption] = useState("Original");
  const [selectedGenre, setSelectedGenre] = useState("All Movies");

  const lastUpdatedDate = "March 10th, 2025";

  const beerRatings = [
    "1 Beer",
    "2 Beers",
    "3 Beers",
    "4 Beers",
    "5 Beers",
    "6 Beers",
  ];

  const genreSet = new Set(
    MovieList.flatMap((movie) => [
      String(movie.genre).trim(),
      ...(Array.isArray(movie.subgenre) ? movie.subgenre.map(String) : []),
    ])
  );

  // Convert to array and sort alphabetically (including "Tight 90")
  const sortedGenres = [...genreSet, "Tight 90"].sort((a, b) =>
    a.localeCompare(b)
  );

  const genres = [
    "All Movies", // Keep "All Movies" at the very top
    ...sortedGenres, // Alphabetically sorted genres including "Tight 90"
    ...beerRatings, // Beer ratings remain at the bottom
  ];

  const handleGenreChange = (event) => {
    setSelectedGenre(String(event.target.value));
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const getSortedMovies = () => {
    let sortedMovies = [...MovieList];

    if (sortOption === "Alphabetical") {
      sortedMovies.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOption === "Chronological") {
      sortedMovies.sort((a, b) => b.year - a.year);
    } else if (sortOption === "Newest") {
      return sortedMovies.slice(-5);
    }

    return sortedMovies;
  };

  const ratedMovies = getSortedMovies().filter(
    (movie) => movie.rating !== undefined
  );

  const filteredMovies = ratedMovies.filter((movie) => {
    const lowerCaseSearch = search.toLowerCase();

    const matchesSearch =
      movie.name.toLowerCase().includes(lowerCaseSearch) ||
      (movie.director &&
        movie.director.toLowerCase().includes(lowerCaseSearch)) ||
      (movie.cast &&
        movie.cast
          .toLowerCase()
          .split(", ")
          .some((actor) => actor.includes(lowerCaseSearch)));

    const matchesGenre =
      selectedGenre === "All Movies" ||
      movie.genre === selectedGenre ||
      (Array.isArray(movie.subgenre) &&
        movie.subgenre.includes(selectedGenre)) ||
      (selectedGenre === "Tight 90" && parseInt(movie.runtime) <= 90) ||
      (selectedGenre.includes("Beer") &&
        parseInt(selectedGenre[0]) === movie.rating);

    return matchesSearch && matchesGenre;
  });

  return (
    <div>
      <body className="searchBody">
        <div>
          <div className="searchAndGenre">
            <div className="genreSign">
              {sortOption === "Newest" ? (
                <>
                  NEWEST REVIEWS
                  <p className="newReviewDate">
                    Last updated on {lastUpdatedDate}
                  </p>
                </>
              ) : selectedGenre && selectedGenre !== "All Movies" ? (
                selectedGenre.toUpperCase()
              ) : (
                "ALL MOVIES"
              )}
            </div>

            <div className="searchAndFiltersContainer">
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                className="searchInput"
                placeholder="Type a movie"
                onChange={(e) => setSearch(e.target.value)}
              />

              <div className="filterContainer">
                <FormControl component="fieldset" className="filter-group">
                  <FormLabel component="legend">Sort By</FormLabel>
                  <RadioGroup
                    row
                    name="filter-options"
                    value={sortOption}
                    onChange={handleSortChange}
                  >
                    <FormControlLabel
                      value="Original"
                      control={<Radio />}
                      label="Default"
                    />
                    <FormControlLabel
                      value="Alphabetical"
                      control={<Radio />}
                      label="A-Z"
                    />
                    <FormControlLabel
                      value="Chronological"
                      control={<Radio />}
                      label="Year &#x2191;&#x2193;"
                    />
                    <FormControlLabel
                      value="Newest"
                      control={<Radio />}
                      label="Newest"
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl variant="standard" className="genre-dropdown">
                  <InputLabel>Filter by Genre</InputLabel>
                  <Select
                    value={selectedGenre}
                    onChange={handleGenreChange}
                    displayEmpty
                  >
                    <MenuItem value="All Movies" style={{ fontWeight: "bold" }}>
                      All Movies
                    </MenuItem>

                    {genres
                      .filter((genre) => genre !== "All Movies")
                      .map((genre) => (
                        <MenuItem key={genre} value={genre}>
                          {genre}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <ScrollArrow />

          <div className="renderContainer">
            {filteredMovies.length > 0 ? (
              filteredMovies.map((movie) =>
                movie.synopsis && movie.synopsis.trim() !== "" ? (
                  <MovieCard key={movie.id} {...movie} />
                ) : (
                  <div className="movieContainer" key={movie.id}>
                    <SimpleModal
                      name={movie.name}
                      poster={movie.poster}
                      rating={movie.rating}
                      year={movie.year}
                      runtime={movie.runtime}
                      rated={movie.rated}
                      genre={movie.genre}
                      subgenre={movie.subgenre}
                    />
                    <div className="movieTitleCard" title={movie.name}>
                      <div className="movieTitleText">
                        {movie.name} ({movie.year})
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <NoResults />
            )}
          </div>
        </div>
      </body>
    </div>
  );
}

export default Ratings;
