import React from "react";

function ArticlesBy(props) {
  return (
    <div>
      <div className="otherArticles">
        <div className="thumbnailTitleContainer">
          <img
            src={props.picture}
            className="otherArticlesThumbnail"
            alt="article"
          ></img>
          <div className="otherTitleSynopsisContainer">
            <p className="otherArticleTitle">{props.otherArticleTitle}</p>
            <p className="otherArticleSynopsis">{props.otherArticleSynopsis}</p>
            <p className="readMore">READ MORE </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticlesBy;
