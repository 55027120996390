import React from "react";
import "./App.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

function App() {
  return (
    <div id="pageWrapper">
      <Navbar />

      {}
      <div id="content">
        <main>{}</main>
      </div>

      {}
      <Footer />
    </div>
  );
}

export default App;
