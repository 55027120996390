import React from "react";
import { Link } from "react-router-dom";
import ArticlesBy from "./ArticlesBy";
import NakedGun from "../src/grabbag-pictures/spoof-nielsen.webp";
import ScotlandPic from "../src/pictures/author-pics/Scotland-McBagpipe-1.png";

import Fade from "react-reveal/Fade";

function Scotland() {
  return (
    <div>
      <div className="aboutWriterContainer">
        <div className="writerThumbnail">
          <img
            src={ScotlandPic}
            className="writerThumbnailImage"
            alt="writer-headshot"
          ></img>
        </div>
        <div className="nameAndBlurbContainer">
          <div className="writerName">Scotland McBagpipe</div>
          <div className="writerBlurb">
            Scotland is a top lad that's bold and smooth - just like a fine
            whisky.
          </div>
        </div>
      </div>
      <div className="alsoWrittenByContainer">
        <p className="seeAlsoHeader">Articles by Scotland</p>
        <Fade bottom>
          <Link to="/spoofs">
            <ArticlesBy
              picture={NakedGun}
              otherArticleTitle={"What Happened to Spoofs?"}
              otherArticleSynopsis={
                "Flying at 35,000 feet an impeccably dressed young Caucasian boy, no older than 10, approaches a row of seats with a tray of coffees. A young Caucasian girl of the same age is sitting on the window side giving off an air of precociousness."
              }
            />
          </Link>
        </Fade>
      </div>
    </div>
  );
}

export default Scotland;
