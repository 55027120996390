import React from "react";
import Modal from "@mui/material/Modal";
import RecipeCard from "./RecipeCard";
import MovieList from "./MovieList";

export default function SimpleModal(props) {
  const [open, setOpen] = React.useState(false);
  const [showRelated, setShowRelated] = React.useState(false);
  const [showDrink, setShowDrink] = React.useState(false);
  const [relatedMovies, setRelatedMovies] = React.useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleRelated = () => {
    if (!showRelated) {
      let movies = MovieList.filter((movie) => {
        if (movie.id === props.id) return false;
        return movie.genre === props.genre;
      })
        .sort(() => 0.5 - Math.random())
        .slice(0, 3);
      setRelatedMovies(movies);
    }
    setShowRelated(!showRelated);
  };

  const toggleDrink = () => setShowDrink(!showDrink);

  let body = (
    <div className="modal">
      <p className="modalCloseX" onClick={handleClose}>
        x
      </p>
      <div className="modal-content">
        <h2 className="tagline">"{props.tagline}"</h2>
        <div className="tagline-container">
          <span className="tagline-writer">- {props.taglineWriter}</span>
        </div>

        {showRelated && (
          <div className="relatedContainer">
            <p className="relatedX" onClick={toggleRelated}>
              X
            </p>
            <div className="relatedTitles"> You may also like ...</div>
            <div className="relatedFlex">
              {relatedMovies.map((movie) => (
                <img key={movie.name} src={movie.poster} alt="Movie Poster" />
              ))}
            </div>
          </div>
        )}

        <div className="topPicsContainer">
          <img src={props.picture1} alt="movie poster" className="picture1" />
          <img src={props.picture2} alt="movie poster" className="picture2" />
        </div>

        <p className="modal-synopsis">{props.synopsis}</p>

        <div className="bonusFeatures">
          <div className="img-reviewed">
            <img
              className="img-img-reviewed"
              src={props.picture3}
              alt="movie cover"
            />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/search?q=where+to+watch+${props.name}`}
            >
              <div className="img-overlay-reviewed">
                <div className="img-title-reviewed">Watch Me!</div>
                <p className="img-description-reviewed">Click to find where</p>
              </div>
            </a>
          </div>
          <div className="letsGo">
            <div className="bonusFeatureBulletContainer">SPECIAL FEATURES</div>
            <div className="bonusFeatureBullets">
              <ul>
                <li>{props.bulletOne}</li>
                <li className="bottomBullet">{props.bulletTwo}</li>
                <li className="seeSimilar" onClick={toggleRelated}>
                  {props.bulletThree} See related movies.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="cast">
          <p className="castText">
            {props.cast}
            {showDrink && <RecipeCard drinkRecipe={props.drinkRecipe} />}
          </p>
        </div>

        <div className="bottomContainerContainer">
          <div className="bottomContainer">
            <div className="pairsWith">
              <div className="iconContainer">
                <i
                  className="fas fa-cocktail fa-3x"
                  style={{ color: "#c5c1c1" }}
                ></i>
              </div>
              <div className="movieNameDrinks">
                <div className="movieNameDrinksText">{props.name}</div>
                <div className="pairsWithTextDiv">
                  Pairs well with{" "}
                  <span className="recommendedDrink" onClick={toggleDrink}>
                    {props.pairs}
                  </span>
                  <span className="noPairs">{props.noPairs}</span>
                </div>
              </div>
              <div className="iconContainer2">
                <i
                  className="fas fa-cocktail fa-3x"
                  style={{ color: "#c5c1c1" }}
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-filmInfo">
          {props.rated && (
            <div className="modal-mpaa-rating">{props.rated}</div>
          )}
          <div className="modal-runtime">
            <i className="far fa-clock"></i>
            {props.runtime}
          </div>
          <div className="modal-year">{props.year}</div>
          <div className="modal-rating">
            <span role="img" aria-label="beer">
              🍺
            </span>
            {props.rating}
            <span className="outOf">/6</span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <img
        onClick={handleOpen}
        src={props.poster}
        alt="Movie Poster"
        className="moviePosters"
      />
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
