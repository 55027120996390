import React from "react";
import Travolta from "../src/pictures/FSLogo/travolta.gif";

const NoResults = (props) => (
  <div>
    <div>
      <div className="noActor">
        <img src={Travolta} alt={"DVD BOX"} className="noActorPicture" />
      </div>
    </div>
    <div className="noActorText">
      Sorry, looks like we don't have what you're looking for
    </div>
  </div>
);

export default NoResults;
