import React from "react";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import Troy from "../coldtake-pictures/troy.jpeg";
import Badrinath from "../coldtake-pictures/badrinath.jpeg";
import Dickpunch from "../coldtake-pictures/dickpunch.jpeg";
import HappyCommunity from "../coldtake-pictures/happy-community.jpeg";
import Classroom from "../coldtake-pictures/classroom.webp";
import Funeral from "../coldtake-pictures/funeral.png";
import Bambi from "../coldtake-pictures/bambi.webp";
import Zingers from "../pictures/topfive/zingers.jpeg";
import koogler from "../topfive-pictures/koogler.jpeg";
import TooFar2Pee from "../topfive-pictures/2far2P.png";
import NotificationBell from "../NotificationBell.js";

function Community() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingColdTakes">
          <span className="underline">Cold Takes</span>
        </Link>{" "}
        &gt; Lamest Episode of Community
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Lamest Episode of Community</p>
          </div>
          <div className="published">PUBLISHED Jul 10, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/dean">
                <span className="blue" id="author-name">
                  Dean
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Lamest Episode of Community"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img src={Troy} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption">Fuckin Dork</p>
            </div>
            <p>
              Community is a comedy about eccentric students and the zany
              situations they get into at their local college. So when Troy, one
              of the the silliest and most over-the-top characters turns 21,
              we'll surely be treated to an episode of inebriated revelry and
              hilarious hijinks, right? WRONG.
            </p>

            <p>
              Season two, episode 10: Mixology Certification is without question
              the lamest 21 minutes in the history of Community. The entire
              episode is an anti-drinking diatribe and the fact that people love
              it is downright baffling. It's about as funny and lighthearted as
              a punch to the dick.
            </p>
            <div className="image-and-caption">
              <img
                src={Dickpunch}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                This actually <em>is</em> funnier than the episode
              </p>
            </div>
            <p>
              Drinking is fantastic. There's no better way to relax and bond
              with friends (or even strangers, for that matter). Rob Corddry
              confirms this in the very same season, no less. When Jeff asks if
              he's allowed to go for a drink he says "Yeah, I quit doing blow -
              not being <em>rad!"</em>.
            </p>
            <p>
              So given the context of the show - a{" "}
              <span className="green">comedy</span>, and the subject matter of
              the episode - <span className="green">turning 21</span>, this
              should have unraveled into a debauched night of imbibement - but
              instead we're treated to an afterschool special.
            </p>
            <div className="image-and-caption">
              <img
                src={Classroom}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Or in this case a duringschool special</p>
            </div>
            <p>
              They did absolutely everything in their power to make drinking
              look like the devil. From slurring to fighting to hiding
              embarassing photos, they truly went out of their way to create the
              ultimate wet blanket of an episode. The message is so unbelievably
              heavy-handed that at one point Troy actually says{" "}
              <span className="green">"Drinking makes people sad"</span>. We
              wish that were a joke (it'd be the <em>only one</em> in the entire
              episode), but he actually fucking said it.
            </p>
            <p>
              The episode <em>could not</em> have been preachier. Bemoaning the
              (LEGAL) consumption of alcohol has no place in a college-based
              comedy. What should have been joyful and fun turned out to be
              sullen and depressing. It was the most jarring change in tone
              since Bambi's mom got plugged by that hunter.
            </p>
            <div className="image-and-caption">
              <img
                src={Bambi}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                These were{" "}
                <strong>
                  <u>not</u>
                </strong>{" "}
                the shots Lil Jon had in mind
              </p>
            </div>
            <p>
              This begs the question: <span className="green">WHY?</span> What
              Sober Sally got the green light for this lame-ass script? If the
              target audience was a sixth grade D.A.R.E. class, I could
              completely get behind an overt anti-drinking message. But
              Community is geared towards college-aged adults who, believe it or
              not, enjoy drinking.
            </p>

            <p>
              Here's what the episode should have been: laughing, smiling, being
              silly, and having a (un)forgettable time at the bar.
            </p>
            <div className="image-and-caption">
              <img
                src={HappyCommunity}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "Holy shit it feels amazing to legally drink!"
              </p>
            </div>
            <p>But here's what the episode was.</p>
            <div className="image-and-caption">
              <img
                src={Funeral}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "We're gathered today to say farewell to comedy"
              </p>
            </div>

            <p>
              Most upsetting of all is the fact that it was directed by Jay
              Chandresekhar - creator of the greatest drinking movie of all time
              in <em>Beerfest</em>. The betrayal is staggering. It's like Martin
              Luther King Jr. revealing himself to be The Grand Wizard of the
              KKK.
            </p>
            <div className="image-and-caption">
              <img
                src={Badrinath}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">What happened to you, Barry?</p>
            </div>
          </div>{" "}
          <Link to="/johnny">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Johnny Utah Is a Piece of Shit</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Lamest Episode of Community"
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/fakemovies">
            <div className="image-and-caption">
              <img
                src={koogler}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Fake Movies We Want to See</p>
            </div>
          </Link>
          <Link to="/bestzingers">
            <div className="image-and-caption">
              <img
                src={Zingers}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">
                Best zingers from minor characters
              </p>
            </div>
          </Link>
          <Link to="/toofarpee">
            <div className="image-and-caption">
              <img
                src={TooFar2Pee}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Dudes who went too far to pee</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Community;
