import React from "react";
import GambolMain from "../topfive-pictures/gambol-main.jpeg";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import Mnemonic from "../grabbag-pictures/mnemonic.jpeg";
import Giamatti from "../grabbag-pictures/giamatti.jpeg";
import Swordfish from "../grabbag-pictures/hackjob.jpeg";
import Wolverine from "../grabbag-pictures/wolverine.webp";
import Batman from "../grabbag-pictures/affleck-batman.jpeg";
import Overtop from "../grabbag-pictures/overthetop.jpeg";
import Thor from "../grabbag-pictures/thor.jpeg";
import Computer from "../grabbag-pictures/computer.jpeg";
import arabia from "../../src/coldtake-pictures/arabia.jpeg";
import Community from "../coldtake-pictures/community.jpeg";
import VideoEmbed from "../VideoEmbed";
import NotificationBell from "../NotificationBell";

function HackIsWack() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingGrabbag">
          <span className="underline">Grab Bag</span>
        </Link>{" "}
        &gt; Hack is Wack
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Hack is Wack</p>
          </div>
          <div className="published">PUBLISHED Aug 10, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/dean">
                <span className="blue" id="author-name">
                  Dean
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Hack is Wack"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={Mnemonic}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Damn, this dude's got like 3 gigs in there
              </p>
            </div>
            <p>
              Despite being an industry driven by technological advancement,
              Hollywood knows astonishingly little about portaying technology.
              From jacked hackers to keyboard wizards, this article runs down
              several mistakes often seen in pulse-pounding cyber slams.
            </p>

            <p>
              First and foremost,{" "}
              <span className="green">programming is not exciting.</span>{" "}
              Hollywood portrays coding as an adrenaline-fueled profession
              exclusively for the brilliant and beautiful, but in reality it's
              writing a few lines of code and then sitting in meetings all
              goddamn day. It's boring - and{" "}
              <span className="green">
                you can tell how boring a job is by how hard they try to make it
                look cool in movies.
              </span>{" "}
              Nowhere is this more clear than in the movie 'Swordfish'.
            </p>
            <p>
              In real life, a hacker is a ponytailed, Cheeto-dusted basement
              dweller. In Swordfish, Hugh Jackman’s hacker is a suave Adonis in
              a nightclub. Real hackers have plenty of time to devise
              cyber-attacks, whereas Hugh has 60 seconds to hack the Department
              of Defense while getting a blowjob with a gun to his head. Think
              about that. Think about{" "}
              <span className="green">how far overboard</span> they had to go to
              make a mundane job interesting.
            </p>

            <div className="image-and-caption">
              <img
                src={Swordfish}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">Just another day at IBM</p>
            </div>

            <p>
              Which leads to another mistake:{" "}
              <span className="green">who's casting these roles?</span> The
              average programmer is about as sexy as a yellow toenail, yet
              Hollywood never fails to cast a stud. Who's sitting there like "We
              need someone to play a convincing software engineer. How about{" "}
              <span className="green">WOLVERINE?</span>".
            </p>
            <div className="image-and-caption">
              <img
                src={Wolverine}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">This USB is going deep in your C-drive</p>
            </div>
            <p>
              I mean, for gods sake - the guys name is basically 'Huge
              Jackedman'. Is that the person you envision when calling tech
              support? And let's not forget all the gorgeous women who are
              allegedly itching to bust out some Embedded C. From Sandra Bullock
              to Angelina Jolie to Mackenzie Davis, programming is evidently
              brimming with part-time supermodels.
            </p>
            <p>
              If casting was even remotely accurate for these movies, every
              programmer on Earth would be played by Paul Giamatti.
            </p>
            <div className="image-and-caption">
              <img
                src={Giamatti}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"We've gotta hack into the mainframe"</p>
            </div>
            <p>
              Going over the top to make humdrum jobs look sexy is a challenging
              task. In fact, one need only watch <i>Over the Top</i> for the
              perfect example of going over the top to make a regular profession
              interesting.
            </p>
            <div className="image-and-caption">
              <img
                src={Overtop}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Just another day of truckin'</p>
            </div>
            <p>
              Pretty much any movie where the title is a profession, the
              real-life job is mind-numbingly dull. The Postman. The Cable Guy.
              The Machinist. At a certain point writers just say 'fuck it' and
              make the movie with nary a hint of the titular job.
            </p>
            <p>
              Take 'The Accountant' with Ben Affleck. He's not so much an
              'accountant' but more an 'assassin who owns a calculator'. He only
              audits the number of bullets used to send his enemies to the
              afterlife. That's a pretty far cry from some workaday hump in
              middle-management.
            </p>
            <div className="image-and-caption">
              <img
                src={Batman}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                A number-crunching office drone? How about{" "}
                <strong>BATMAN?</strong>
              </p>
            </div>

            <p>
              Another mistake is{" "}
              <span className="green">
                not understanding how fast technology moves.
              </span>{" "}
              There are countless movies where the government pulls some crusty
              old hacker out of prison after decades to fight new bad guys. A
              shining example of this moronic premise is in the movie 'Blackhat'
              with Chris Hemsworth.
            </p>
            <div className="image-and-caption">
              <img src={Thor} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption">
                Wolverine is busy? How about <strong>THOR?</strong>
              </p>
            </div>
            <p>
              Some fields change at a glacial pace. Take carpentry - a field
              that has remained unchanged since the beginning of time. There's
              no need for technological advancement when your source material is
              prehistoric. Computer technology, on the other hand,{" "}
              <span className="green">doubles every couple of months.</span> So
              when the CIA drags some wrinkled old hacker out of prison after
              several years, <em>what do they think he's gonna do?</em>
            </p>

            <div className="image-and-caption">
              <img
                src={Computer}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Quick! Pass me a floppy disk and go to Ask Jeeves. I've got him.
              </p>
            </div>
            <p>
              This is a man who last breathed free air when connecting to the
              internet sounded like electricity having an orgasm. A man from a
              time when a phonecall flushed your three-day Limewire download of
              50 Cent's 'In Da Club' right down the toilet. How could he even
              begin to comprehend the magnitude of each{" "}
              <span className="green">quantum leap</span> since his
              incarceration?
            </p>
            <p>
              The only way Hollywood seems to connect technology to speed is in
              the blistering rate that hackers type; it's like they're playing a
              piano on crystal meth. Not only is the code written faster than
              any human can even comprehend, but it <em>never </em> throws an
              error. And let's not forget - hackers{" "}
              <span className="green">always</span> are granted access. Unless
              we're talking about real life.
            </p>
            <div className="youtube-container">
              <VideoEmbed videoId={"bXNwMF5JndM"} />
            </div>
            <p>
              If anyone is fuming with us for revealing these stale Hollywood
              cliches, feel free to attempt tracking us down by tracing our call
              for a suspenseful 90-second conversation, which we'll expertly
              wrap up in about 88 seconds, leaving you with nothing but a dial
              tone and a deep sense of frustration.
            </p>
          </div>{" "}
          <Link to="/boozecruize">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Ride the Booze Cruize</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={"Check out this article from FilmSlobs! Hack is Wack"}
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/community">
            <div className="image-and-caption">
              <img
                src={Community}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Lamest Community Episode</p>
            </div>
          </Link>
          <Link to="/dramaischeap">
            <div className="image-and-caption">
              <img
                src={arabia}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Drama is cheap</p>
            </div>
          </Link>
          <Link to="/mostdisappointing">
            <div className="image-and-caption">
              <img
                src={GambolMain}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Most disappointing badasses</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HackIsWack;
