let TVList = [
  {
    id: 1,
    name: "Peep Show",
    year: "2003-2015",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmQ0MGMwNjMtNmEyMC00OTA2LTg4YTEtNjBhNjNkYTgzMmZlXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "British",
    runtime: "9 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 2,
    name: "Strangers with Candy",
    year: "1999-2000",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzkyOTg3ZTYtYTkxZi00ZDFmLWI2MzYtOGMxNTczYmJhNjE5XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "3 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 3,
    name: "Seinfeld",
    year: "1989-1998",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmRjNjZjN2ItN2FkYi00ZDg0LWExN2EtMTU2ODUwNWU1M2NhXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "9 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 4,
    name: "The Inbetweeners",
    year: "2008-2010",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjQ5NTcwYjItYjdiNC00NmQyLWI0MzMtMmZmZjRlZTc5NzNmXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "British",
    runtime: "3 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 5,
    name: "It's Always Sunny in Philadelphia",
    year: "2005",
    poster:
      " https://m.media-amazon.com/images/M/MV5BZTEyY2Q1MmYtNDZmOS00NDM2LWFjZDAtMTU5MmU1MWU3YzNhXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "16 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 6,
    name: "Silicon Valley",
    year: "2014-2019",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjhhYmZjZjgtOTc3Mi00ZmY3LWI3MTUtYzcyOGMxYWFmN2YyXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "6 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 7,
    name: "The Office",
    year: "2005-2013",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjQwYzBlYzUtZjhhOS00ZDQ0LWE0NzAtYTk4MjgzZTNkZWEzXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "9 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 8,
    name: "Toast of London",
    year: "2012-2020",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDBlYTFmMWUtYzkzZC00ZGRkLTkzNjUtYWFjMDEyYjMwYjM2XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "British",
    runtime: "3 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 9,
    name: "Cobra Kai",
    year: "2018-2025",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjA3NDkwNzktNjJkYi00ODNhLWFhYzQtYzk5NjU4MDM0OWZmXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "6 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 10,
    name: "Game of Thrones",
    year: "2011-2019",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTNhMDJmNmYtNDQ5OS00ODdlLWE0ZDAtZTgyYTIwNDY3OTU3XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Fantasy",
    subgenre: "",
    runtime: "8 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 11,
    name: "Breaking Bad",
    year: "2008-2013",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzU5ZGYzNmQtMTdhYy00OGRiLTg0NmQtYjVjNzliZTg1ZGE4XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "5 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 12,
    name: "Parks and Recreation",
    year: "2009-2015",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDlhMzAwNTAtNTk2NS00MTdkLWE3ZWYtMDU0MTFiYmU2ZTc0XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "7 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 13,
    name: "Stranger Things",
    year: "2016-2025",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE2N2MyMzEtNmU5NS00OTI0LTlkNTMtMWM1YWYyNmU4NmY0XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Fantasy",
    subgenre: "Horror",
    runtime: "5 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 14,
    name: "How I Met Your Mother",
    year: "2005-2014",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjg1MDQ5MjQ2N15BMl5BanBnXkFtZTYwNjI5NjA3._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "9 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 15,
    name: "Man Down",
    year: "2013-2017",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTBlODEwMjEtMjhiNS00YjAzLTkzYTEtMmI4MjNhNzdjNjFjXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "British",
    runtime: "4 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 16,
    name: "Dexter",
    year: "2006-2013",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ3YmQ4YzMtOTkyZC00YmM5LThhZjEtM2E0MjFkNTc0OGJhXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "Mystery",
    runtime: "8 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 17,
    name: "Curb Your Enthusiasm",
    year: "2000-2024",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmFiZDY2ZDItZDliOC00Y2JhLTliMWYtODkzMTA0YmIwZDA3XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "12 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 18,
    name: "Coupling",
    year: "2000-2004",
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2EzMTA4MjgtOTBiYS00NGI2LWJhYTQtYWUzZDI2YjI2NTJlXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "British",
    runtime: "4 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 19,
    name: "Friday Night Dinner",
    year: "2011-2020",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmE5NDRmODctYTAwYi00Zjg5LWFmMGItYWI3ZTAwNjI5Y2I4XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "6 Seasons",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 20,
    name: "Chernobyl",
    year: "2011-2020",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmQ0Y2JiM2EtOWIwMy00MWVjLTgxZDctZTQzOWU3ODkzMWY4XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Drama",
    subgenre: "Thriller",
    runtime: "1 Season",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: "",
    picture2: "",
    picture3: "",
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
];

export default TVList;
