import React from "react";
import junkDrawer from "../src/pictures/FSLogo/grabbag.jpg";
import rocky from "../src/pictures/topfive/topfive.jpeg";
import Fade from "react-reveal/Fade";
import coldTakes from "../src/pictures/the-shining/shining-cold-take.jpeg";
import { Link } from "react-router-dom";

function Writing() {
  return (
    <div>
      <div className="writingPageDescription" style={{ marginBottom: "3rem" }}>
        <h2>
          <span className="green">ARTICLES</span>
        </h2>
        <p className="writingPageSubheader">
          Rankings, rants, and reviews from the FilmSlobs team
        </p>
      </div>
      <div className="writing-outer-container">
        <div className="writing-container">
          <Link to="./WritingTopFive" style={{ textDecoration: "none" }}>
            <div className="writing-item" style={{ marginBottom: "80px" }}>
              <Fade left>
                <div className="writing-description has-margin-right">
                  <h6>Ranking Lists</h6>
                  <h1>Top Fives</h1>
                  <p>
                    Ever wonder who's the biggest badass in movie history? Who
                    had the slickest zinger? What movie is most underrated? How
                    about the best movies by decade, or worst? This is where we
                    catalog all the answers to questions that no one asked.
                  </p>
                </div>
              </Fade>
              <Fade left>
                <div className="writing-img">
                  <img
                    className="writing-image"
                    src={rocky}
                    alt="Rocky Balboa"
                    style={{ width: "100%" }}
                  />
                </div>
              </Fade>
            </div>
          </Link>
          <Link to="./WritingColdTakes" style={{ textDecoration: "none" }}>
            <div className="writing-item" style={{ marginBottom: "80px" }}>
              <Fade right>
                <div className="writing-img has-margin-right">
                  <img
                    className="writing-image"
                    src={coldTakes}
                    alt="Frozen Man"
                    style={{ width: "100%" }}
                  />
                </div>
              </Fade>

              <Fade right>
                <div className="writing-description">
                  <h6>Opinion Pieces</h6>
                  <h1>Cold Takes</h1>
                  <p>
                    Rants and raves about movies and TV shows which have left
                    the public consciousness but still merit discussion. We
                    address shoulda-woulda-coulda's and provide alternative
                    opinions that are best served with ice.
                  </p>
                </div>
              </Fade>
            </div>
          </Link>
          <Link to="./WritingGrabBag" style={{ textDecoration: "none" }}>
            <div className="writing-item" style={{ marginBottom: "80px" }}>
              <Fade left>
                <div className="writing-description has-margin-right">
                  <h6>Miscellaneous</h6>
                  <h1>Grab Bag</h1>
                  <p>
                    Extended reviews and assorted ramblings from the demented
                    minds behind FilmSlobs. Much like the bits of an animal that
                    don't make the cut, this section is our leftovers and it's
                    truly offal.
                  </p>
                </div>
              </Fade>

              <Fade left>
                <div className="writing-img">
                  <img
                    className="writing-image"
                    src={junkDrawer}
                    alt="Movie Shelves"
                    style={{ width: "100%" }}
                  />
                </div>
              </Fade>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Writing;
